import React from 'react';
import searchIcon from '../../assets/icons/searchIcon.svg';

const SearchInput = ({ ...props }) => {
  return (
    <div className='search_container'>
      <input type='text' placeholder='Search for food' {...props} />
      <img src={searchIcon} alt='' />
    </div>
  );
};

export default SearchInput;
