import React from 'react';

const Count = ({ value, handleDecrease, handleIncrease }) => {
  return (
    <div className='count_container'>
      <p>{value}</p>
      <div>
        <button onClick={handleIncrease}>+</button>
        <button onClick={handleDecrease}>-</button>
      </div>
    </div>
  );
};

export default Count;
