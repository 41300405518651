import { useContext, useState, useEffect } from 'react';
import CartBadge from '../CartBadge';
import ARROWLEFT from '../../assets/icons/arowLeft.svg';
import { Link, useNavigate } from 'react-router-dom';
import { useCartStateContext } from '../../context/cartContext';
import companyContext from '../../context/companyContext';
import { Dropdown } from 'react-bootstrap';
import {
  getAgents,
  getShowMultiBrands,
  saveItemsInCart
} from '../../services/localService';

const Navbar2 = ({ useBackButton }) => {
  const navigate = useNavigate();
  const [company] = useContext(companyContext);
  const { totalItemsInCart, setTotalItemsInCart } = useCartStateContext();
  const [initials, setInitials] = useState('');
  const [agents, setAgents] = useState([]);
  const allAgents = getAgents() ?? [];
  const showMultiBrands = getShowMultiBrands();

  const getInitials = (name) => {
    // let name = company?.name;
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials = '';

    if (name) {
      initials = [...name?.matchAll(rgx)];
    } else {
      initials = [];
    }

    initials = (
      (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();

    return initials ?? 'DM';
  };

  // useEffect(() => {
  //   if (company.name) {

  //   }
  // }, [company]);

  return (
    <nav className='navbar_container'>
      {useBackButton ? (
        <div className='goBackContainer'>
          <img src={ARROWLEFT} alt='' onClick={() => navigate(-1)} />
        </div>
      ) : (
        <div className='store_name_and_initials'>
          <span>
            <svg
              width='60'
              height='20'
              viewBox='0 0 60 20'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M2.73836 6.5028L2.73836 2.84895L7.41601 7.5266L5.46512 9.58126L2.73836 6.5028Z'
                fill='#292A2E'
              />
              <path
                d='M3.5973 19.543H1.06744e-06L0 7.33295L3.5973 7.33295L3.5973 19.543Z'
                fill='#292A2E'
              />
              <path
                d='M1.06744e-06 0V9.47749H3.5973V3.74934L1.06744e-06 0Z'
                fill='#292A2E'
              />
              <path
                d='M1.06744e-06 19.543L3.71227e-08 16.43H12.3138V19.543H1.06744e-06Z'
                fill='#292A2E'
              />
              <path
                d='M5.46512 9.58126V5.98396H12.3138V9.58126H5.46512Z'
                fill='#292A2E'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M16.0841 12.7635C16.0841 10.7673 14.4251 9.58126 12.3138 9.58126V5.98396C16.6375 5.98396 19.1971 8.99053 19.1971 12.7635C19.1971 16.5364 16.0863 19.543 12.3138 19.543V16.43C14.4251 16.43 16.0841 14.7597 16.0841 12.7635Z'
                fill='#292A2E'
              />
              <path
                d='M22.782 4.33059C22.2248 4.33059 21.7424 4.12668 21.3334 3.71748C20.9245 3.30828 20.7207 2.82557 20.7207 2.26795C20.7207 1.71033 20.9245 1.22345 21.3334 0.805931C21.7424 0.388409 22.2248 0.178955 22.782 0.178955C23.3559 0.178955 23.8481 0.388409 24.257 0.805931C24.6659 1.22345 24.8697 1.71172 24.8697 2.26795C24.8697 2.82557 24.6646 3.30828 24.257 3.71748C23.8481 4.12668 23.3559 4.33059 22.782 4.33059ZM21.1116 18.9549V5.89803H24.4788V18.9549H21.1116Z'
                fill='#292A2E'
              />
              <path
                d='M29.3583 13.8101C29.8102 15.4469 31.037 16.2653 33.0388 16.2653C34.3266 16.2653 35.2997 15.8298 35.961 14.96L38.6752 16.5275C37.3874 18.3904 35.491 19.3225 32.9861 19.3225C30.8291 19.3225 29.0977 18.6692 27.7918 17.3639C26.4874 16.0586 25.8345 14.4135 25.8345 12.4286C25.8345 10.4616 26.4777 8.82067 27.7655 7.50568C29.0533 6.19208 30.7057 5.53459 32.7241 5.53459C34.6371 5.53459 36.2174 6.19625 37.4609 7.51955C38.7043 8.84286 39.3267 10.4797 39.3267 12.4286C39.3267 12.8641 39.2838 13.326 39.1964 13.8129H29.3583V13.8101ZM29.307 11.1996H35.9623C35.771 10.3118 35.3746 9.65016 34.7743 9.21461C34.1741 8.77905 33.4907 8.56128 32.7255 8.56128C31.8203 8.56128 31.0731 8.79292 30.4811 9.25345C29.8892 9.71536 29.4983 10.3631 29.307 11.1996Z'
                fill='#292A2E'
              />
              <path
                d='M55.0664 5.53181C56.5622 5.53181 57.7585 6.02008 58.6554 6.99383C59.5509 7.96897 60 9.27425 60 10.911V18.9549H56.6329V11.1469C56.6329 10.3631 56.4416 9.75419 56.059 9.31864C55.6764 8.88308 55.1371 8.66531 54.4412 8.66531C53.6746 8.66531 53.0799 8.91776 52.653 9.42267C52.226 9.92758 52.0139 10.6586 52.0139 11.6157V18.9535H48.6467V11.1469C48.6467 10.3631 48.4554 9.75419 48.0728 9.31864C47.6902 8.88308 47.1496 8.66531 46.4551 8.66531C45.7065 8.66531 45.1104 8.91776 44.6668 9.42267C44.2233 9.92758 44.0015 10.6586 44.0015 11.6157V18.9535H40.6343V5.89801H44.0015V7.28235C44.7847 6.11579 45.9935 5.5332 47.6292 5.5332C49.2304 5.5332 50.4128 6.16018 51.178 7.41413C52.0472 6.15879 53.3433 5.53181 55.0664 5.53181Z'
                fill='#292A2E'
              />
            </svg>
          </span>
        </div>
      )}

      <CartBadge badgeContent={totalItemsInCart} />
    </nav>
  );
};

export default Navbar2;
