import { useState, useEffect, useContext } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { AllProducts, SingleProduct, Cart, MultiStore } from '../index';
import companyContext from '../../context/companyContext';

import { ScaleLoader } from 'react-spinners';
import { Helmet } from 'react-helmet';
import Closed from '../Closed';

import { useSearchStateContext } from '../../context/seachedContext';
import { Button } from '../../components';
const Home = () => {
  const { isFindingBrand, isError } = useSearchStateContext();
  const [company] = useContext(companyContext);
  const [shopClosed, setShopClosed] = useState(false);
  // const [isError, setIsError] = useState(false);
  const { pathname } = useLocation();

  useEffect(
    () => {
      if (company && company.weekdayHours) {
        const weekdayHours = company?.weekdayHours;
        const now = new Date().getTime();
        const openingTime = new Date().setHours(
          weekdayHours?.startTime?.hour,
          weekdayHours?.startTime?.minute,
          weekdayHours?.startTime?.second,
          weekdayHours?.startTime?.nano
        );

        const closingTime = new Date().setHours(
          weekdayHours?.closingTime?.hour,
          weekdayHours?.closingTime?.minute,
          weekdayHours?.closingTime?.second,
          weekdayHours?.closingTime?.nano
        );

        if (openingTime <= now && now < closingTime) {
        } else {
          setShopClosed(true);
        }
      }
    },
    [
      // company, pathname
    ]
  );

  return (
    <div>
      {isFindingBrand ? (
        <div className='_loading'>
          <ScaleLoader
            color={'#6E20CA'}
            loading={isFindingBrand}
            size={150}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      ) : (
        <>
          <Helmet>
            <meta charSet='utf-8' />
            <title>DIEM - *844#</title>
          </Helmet>
          {isError ? (
            <div className='no_brand_found'>
              <p>404 | No brand/shop found.</p>
              <h1>Dial *844# to order from your favourite brand.</h1>
              <a href='https://twitter.com/justdiemgh' className='follow_us'>
                @justdiemgh
              </a>
              <a
                href='/'
                className='tw-mt-4 primary_button !hover:tw-text-white'
              >
                Home
              </a>
            </div>
          ) : (
            <>
              {shopClosed ? (
                <Closed company={company} />
              ) : (
                <Routes>
                  {/* <Route path='/' element={<ResolveNoSlug />} /> */}
                  <Route path='/' element={<MultiStore />} />
                  <Route path='/:slug' element={<AllProducts />} />
                  <Route path='/agent/:slug' element={<AllProducts />} />
                  {/* <Route path='/product' element={<AllProducts />} /> */}
                  <Route
                    path='/:slug/product/:productId'
                    element={<SingleProduct />}
                  />
                  <Route path='/cart' element={<Cart />} />
                  <Route path='/checkout' element={<Cart />} />
                </Routes>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Home;
