const brandInitials = (name) => {
  // let name = company?.name;
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

  let initials = '';

  if (name) {
    initials = [...name?.matchAll(rgx)];
  } else {
    initials = [];
  }

  initials = (
    (initials?.shift()?.[1] || '') + (initials?.pop()?.[1] || '')
  ).toUpperCase();

  return initials ?? 'DM';
};

export default brandInitials;
