import PropTypes from 'prop-types';
import CartIcon from '../../assets/icons/cart.svg';
import { useNavigate } from 'react-router-dom';
import { useCartStateContext } from '../../context/cartContext';

const CartBadge = ({ badgeContent }) => {
  const navigate = useNavigate();
  const { itemsInCart, setItemsInCart } = useCartStateContext();

  const handleClick = () => {
    let toCart = itemsInCart.filter((item) => {
      return item.variants.qty !== 0;
    });

    setItemsInCart([...toCart]);
  };

  return (
    <div
      className='cartbadge_container'
      onClick={() => {
        handleClick();
        navigate('/cart');
      }}
    >
      <img src={CartIcon} alt='cart' />
      <span>{badgeContent}</span>
    </div>
  );
};

CartBadge.propTypes = {
  badgeContent: PropTypes.number.isRequired
};

export default CartBadge;
