import { StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import toast from "react-hot-toast";
import { FaMapMarkedAlt } from "react-icons/fa";
import CASH from "../../assets/icons/cash.svg";
import PHONE from "../../assets/icons/phone.svg";
import {
  Button,
  Input,
  MapComponent,
  PhoneInput,
  Select,
  ToastNotification,
} from "../../components";
import { getCompany, getCompanyId } from "../../services/localService";
import { orderSummaryService } from "../../services/restService";

const googleMapApiKey = process.env.REACT_APP_MAP_API_KEY;

const lib = ["places"];

const OrderSummary = ({
  deliveryOption,
  setDeliveryOption,
  paymentType,
  setPaymentType,
  cartItems,
  setCartItems,
  setOpenModal,
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: googleMapApiKey,
    libraries: lib,
  });

  const paymentMethods = [
    {
      type: "MoMo",
      icon: PHONE,
      desc: "Pay with mobile money",
    },
    {
      type: "CASH",
      icon: CASH,
      desc: "Pay with cash",
    },
  ];

  const c = getCompany();

  const { company, companyID } = useMemo(() => {
    return { company: c, companyID: getCompanyId() };
  }, [c]);

  const [isPlacingOrder, setIsPlacingOrder] = useState(false);
  // const [company] = useContext(companyContext);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectBranch] = useState({});
  const [subTotal, setSubTotal] = useState(0);
  const [showMap, setShowMap] = useState(false);
  const [searchBox, setSearchBox] = useState(null);
  const [userAddress, setUserAddress] = useState({});

  const [orderSumm, setOrderSumm] = useState({
    name: "",
    msisdn: "",
    deliveryOption: "",
    branch: "",
    subTotal: "",
    paymentType: "",
  });

  const resetOrderSumm = {
    name: "",
    msisdn: "",
    deliveryOption: "",
    branch: "",
    subTotal: "",
    paymentType: "",
  };

  const handleHideMap = () => {
    setShowMap(!showMap);
  };

  const handleChange = (e) => {
    setOrderSumm({ ...orderSumm, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    let subT = cartItems?.reduce((acc, item) => {
      return acc + Number(item?.variants?.price) * Number(item?.variants?.qty);
    }, 0);

    // let totalCartItems = cartItems?.reduce((acc, item) => {
    //   return acc + Number(item?.variants?.quantity);
    // }, 0);

    // setTotalItemsInCart(totalCartItems);
    setSubTotal(subT);
    // saveItemsInCart(cartItems);
  }, [cartItems]);

  useEffect(() => {
    if (cartItems[0]?.appId) getBranches(cartItems[0]?.appId);
  }, [cartItems]);

  const getBranches = async (appId) => {
    try {
      const {
        data: { data },
      } = await orderSummaryService.getBranches(appId);

      setBranches(data);
    } catch (error) {
      console.log(error);
    }
  };

  const onPlacesChanged = () => {
    const address = searchBox.getPlaces();

    if (address && address.length > 0) {
      let location = address[0].geometry.location;
      const lat = location.lat();
      const lng = location.lng();

      const suburb = address[0].address_components.find((st) => {
        return st?.types?.includes("sublocality");
      });
      const city = address[0].address_components.find((st) => {
        return st?.types?.includes("locality");
      });
      const country = address[0].address_components.find((st) => {
        return st?.types?.includes("country");
      });
      const street = address[0].address_components.find((st) => {
        return st?.types?.includes("route");
      });

      setUserAddress({
        suburb: suburb ? suburb?.long_name : "",
        city: city ? city?.long_name : "",
        longitude: lng,
        latitude: lat,
        formattedAddress: address[0].formatted_address,
        formatted_address: address[0].formatted_address,
        streetOrFarm: street ? street?.long_name : "",
        country: country ? country?.long_name : "",
        geometry: address[0].geometry,
      });
    }
  };
  const onSBLoad = (ref) => {
    setSearchBox(ref);
  };

  const [productsInCart, setProductsInCart] = useState([]);

  useEffect(() => {
    const prodToCart = cartItems.map((i) => {
      return {
        name: i.name,
        productId: i.idString,
        variants: [{ ...i.variants, quantity: i.variants.qty }],
      };
    });

    for (let i in prodToCart) {
      delete prodToCart[i]?.variants[0]?.qty;
      delete prodToCart[i]?.variants[0]?.writable;
    }
    setProductsInCart(prodToCart);
  }, [cartItems]);

  const handleSubmitOrder = async () => {
    setIsPlacingOrder(true);
    placingOrder();
    try {
      const msisdn = orderSumm.msisdn;

      const orderObj = {
        name: orderSumm.name,
        msisdn: orderSumm.msisdn,

        productList: productsInCart,
        delivery: {
          name: orderSumm.name,
          msisdn: orderSumm.msisdn,
          branch: selectedBranch,
          status: true,
          address: {
            formattedAddress: userAddress.formattedAddress,
            latitude: userAddress.latitude,
            longitude: userAddress.longitude,
            streetOrFarm: userAddress.streetOrFarm,
            suburb: userAddress.suburb,
            city: userAddress.city,
            country: userAddress.country,
          },
        },
        payment: {
          ownerName: orderSumm.name,
          paymentType: paymentType,
          appId: companyID,
          amount: subTotal,
        },
      };

      const pickUpOrder = {
        name: orderSumm.name,
        msisdn: orderSumm.msisdn,

        productList: productsInCart,
        delivery: {
          name: orderSumm.name,
          msisdn: orderSumm.msisdn,
          branch: selectedBranch,
          status: false,
        },
        payment: {
          ownerName: orderSumm.name,
          paymentType: paymentType,
          appId: companyID,
          amount: subTotal,
        },
      };

      let orderOption = deliveryOption === "Delivery" ? orderObj : pickUpOrder;

      const {
        data: { data },
      } = await orderSummaryService.order(companyID, orderOption);

      let orderId = data[0].idString;
      let amount = subTotal;

      if (paymentType === "CASH") {
        setOrderSumm(resetOrderSumm);
        setSelectBranch("");
        setOpenModal(true);
      } else {
        handlePayForOrder(orderId, companyID, amount, orderSumm.msisdn);
      }
      setIsPlacingOrder(false);
    } catch (error) {
      console.log(error);
      notify();
      setIsPlacingOrder(false);
    }
  };

  const notify = () =>
    toast.error("Your order couldn’t be placed. Kindly try again.", {
      duration: 5000,
    });

  const placingOrder = () =>
    toast.loading("Placing your order", {
      duration: 3000,
    });

  const handlePayForOrder = async (orderId, appId, amount, msisdn) => {
    try {
      const { data } = await orderSummaryService.payForOrder(
        appId,
        orderId,
        amount,
        msisdn
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="order_summary_items_container">
      <>
        <ToastNotification />
      </>
      <div className="d-flex gap_4 flex-column">
        <p className="label">Select delivery option</p>

        <div className="d-flex gap_6 align-items-center">
          {["Delivery", "Pick up"]
            .filter((x) => {
              if (x === "Delivery" && !company?.enableDelivery) {
                return false;
              } else {
                return true;
              }
            })
            .map((option, index) => (
              <p
                className={
                  deliveryOption === option
                    ? "active_delivery_option cursor-pointer"
                    : "inactive_delivery_option cursor-pointer"
                }
                key={index}
                onClick={() => {
                  setDeliveryOption(option);
                }}
              >
                {option}
              </p>
            ))}
        </div>
      </div>

      {branches.length > 0 && (
        <div className="d-flex gap_4 flex-column">
          <p className="label">Select branch</p>

          <Select>
            <Dropdown.Toggle id="dropdown_primary">
              {Object.keys(selectedBranch).length > 0
                ? selectedBranch?.name
                : "Pick a branch"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {branches.map((branch, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => {
                    setSelectBranch(branch);
                  }}
                  className="capitalize"
                >
                  {branch.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Select>
        </div>
      )}

      <div className="d-flex gap_4 flex-column">
        <div className="d-flex align-items-center justify-content-between">
          <p className="amount_to_pay">Subtotal: </p>
          <p className="amount_">Ghs {Number(subTotal).toFixed(2)}</p>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <p className="amount_to_pay">Total: </p>
          <p className="amount_">Ghs {Number(subTotal).toFixed(2)}</p>
        </div>
      </div>

      <div className="d-flex gap_4 flex-column">
        <p className="label">Select payment method</p>

        <div className="d-flex flex-column gap_6">
          {paymentMethods
            ?.filter((x) => {
              if (x.type === "MoMo" && !company?.enablePayments) {
                return false;
              } else {
                return true;
              }
            })
            .map((method, index) => (
              <div key={index} className="d-flex align-items-center gap_4">
                <Input
                  type="radio"
                  disabled={method.type === "MoMo" && !company?.enablePayments}
                  checked={paymentType === method.type}
                  name="paymentMethod"
                  id={method.type}
                  onChange={() => {
                    setPaymentType(method.type);
                  }}
                />
                <label
                  htmlFor={method.type}
                  className="d-flex align-items-center gap_4"
                >
                  <img src={method.icon} alt="" />
                  <p className="payment_method_desc">{method.desc}</p>
                </label>
              </div>
            ))}
        </div>
      </div>

      {paymentType === "MoMo" ? (
        <div className="d-flex gap_4 flex-column">
          <Input
            labelFor={"Name"}
            placeholder="Adom"
            labelName={"Full name"}
            name="name"
            onChange={handleChange}
            value={orderSumm.name}
          />

          {deliveryOption === "Delivery" && (
            <>
              {isLoaded && (
                <div className="_location_container">
                  <StandaloneSearchBox
                    onLoad={onSBLoad}
                    onPlacesChanged={onPlacesChanged}
                  >
                    <Input
                      labelFor={"Location"}
                      placeholder="eg. Abeka, 2nd Street"
                      labelName={"Location"}
                      defaultValue={userAddress?.formatted_address}
                    />
                  </StandaloneSearchBox>

                  <div
                    type="button"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Map"
                    className="target_"
                    onClick={() => {
                      setShowMap(true);
                    }}
                  >
                    <FaMapMarkedAlt style={{ fontSize: "20px" }} />
                  </div>
                </div>
              )}
            </>
          )}

          {!isLoaded && (
            <Input
              labelFor={"Location"}
              placeholder="eg. Abeka, 2nd Street"
              labelName={"Location"}
              defaultValue={userAddress?.formatted_address}
              name="location"
              onChange={handleChange}
            />
          )}
          <PhoneInput
            labelFor={"Mobile money"}
            labelName={"Mobile money number"}
            country={"gh"}
            value={orderSumm.msisdn}
            onChange={(phone) => {
              setOrderSumm({ ...orderSumm, msisdn: phone });
            }}
          />
        </div>
      ) : (
        <>
          <div className="d-flex gap_4 flex-column">
            <Input
              labelFor={"Name"}
              placeholder="Adom"
              labelName={"Full name"}
              name="name"
              value={orderSumm.name}
              onChange={handleChange}
            />
            {deliveryOption === "Delivery" && (
              <>
                {isLoaded && (
                  <div className="_location_container">
                    <StandaloneSearchBox
                      onLoad={onSBLoad}
                      onPlacesChanged={onPlacesChanged}
                    >
                      <Input
                        labelFor={"Location"}
                        placeholder="eg. Abeka, 2nd Street"
                        labelName={"Location"}
                        defaultValue={userAddress?.formatted_address}
                      />
                    </StandaloneSearchBox>

                    <div
                      type="button"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Map"
                      className="target_"
                      onClick={() => {
                        setShowMap(true);
                      }}
                    >
                      <FaMapMarkedAlt style={{ fontSize: "20px" }} />
                    </div>
                  </div>
                )}

                {!isLoaded && (
                  <Input
                    labelFor={"Location"}
                    placeholder="eg. Abeka, 2nd Street"
                    labelName={"Location"}
                    defaultValue={userAddress?.formatted_address}
                    name="location"
                    onChange={handleChange}
                  />
                )}
              </>
            )}

            <PhoneInput
              labelFor={"Mobile money"}
              labelName={"Mobile number"}
              country={"gh"}
              value={orderSumm.msisdn}
              onChange={(phone) => {
                setOrderSumm({ ...orderSumm, msisdn: phone });
              }}
            />
          </div>
        </>
      )}

      <div>
        <Button
          variant={"primary_button w_full"}
          disabled={
            (branches.length > 0 && Object.keys(selectedBranch).length < 1) ||
            orderSumm.name === "" ||
            orderSumm.msisdn === "" ||
            isPlacingOrder
          }
          onClick={handleSubmitOrder}
        >
          Place order
        </Button>
      </div>

      <MapComponent
        showMap={showMap}
        handleHideMap={handleHideMap}
        setUserAddress={setUserAddress}
        userAddress={userAddress}
      />
    </div>
  );
};

export default OrderSummary;
