const Input = ({ labelName, labelFor, ...props }) => {
  return (
    <div className='primary_input_container'>
      {labelName && <label htmlFor={labelFor}>{labelName}</label>}

      <input {...props} />
    </div>
  );
};

export default Input;
