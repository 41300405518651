import React, { useState, useEffect, useContext } from "react";
import { Layout, Product, Loading } from "../../components";
import { Link, useParams, useLocation } from "react-router-dom";
import { productsService, brandService } from "../../services/restService";
import companyContext from "../../context/companyContext";
import {
  getCompanyId,
  saveCompanyId,
  getItemsInCart,
  removeAllItemsFromCart,
  saveCompany,
  getCompany,
  saveSlug,
  saveAgents,
  saveShowMultiBrands,
} from "../../services/localService";
import { useSearchStateContext } from "../../context/seachedContext";
import { useCartStateContext } from "../../context/cartContext";
const AllProducts = () => {
  const pathname = useLocation()?.pathname;

  const { slug } = useParams();
  const appId = getCompanyId();
  const brand = getCompany();
  const itemsInCart = getItemsInCart();
  const [company, setCompany] = useContext(companyContext);
  const { searchResult, searchTerm, setIsError, setIsFindingBrand } =
    useSearchStateContext();
  const { setItemsInCart } = useCartStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const [allOtherProducts, setAllOtherProducts] = useState([]);
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    const getBrand = async (slug) => {
      // saveSlug(slug);
      let slug_ = slug?.replace(/ /g, "_");

      // setIsLoading(true);
      if (appId && brand && slug?.replace(/_/g, " ") === brand.name) {
        setCompany(brand);

        if (itemsInCart) {
          setItemsInCart(itemsInCart);
        }

        setIsLoading(false);
        // setIsFindingBrand(false);

        await Promise.all([
          getAllProducts(brand?.appId),
          getCollections(brand?.appId),
        ]);

        return;
      }

      try {
        const {
          data: { data },
        } = await brandService.getBrandBySlug(slug_);
        let company__ = data[0];
        const weekdayHours = data[0]?.productOrderingProperties?.weekdayHours;
        saveSlug(slug);
        saveCompany({
          appId: company__?.idString,
          name: company__?.name,
          weekdayHours,
        });
        setCompany({
          appId: company__?.idString,
          name: company__?.name,
          weekdayHours,
        });
        saveCompanyId(company__?.idString);

        removeAllItemsFromCart();
        setItemsInCart([]);

        await Promise.all([
          getAllProducts(company__?.idString),
          getCollections(company__?.idString),
        ]);
      } catch (error) {
        console.log(error);

        setIsError(true);
      }
    };

    getBrand(slug);
  }, []);

  useEffect(() => {
    //  const companyId = params.get('brand');

    handleGetAllAgentsPromise();

    if (pathname?.includes("agent")) {
      saveShowMultiBrands({ state: true, path: "" });
      return;
    }
    saveShowMultiBrands({ state: false, path: "" });
  }, []);

  const getAllProducts = async (id) => {
    setIsLoading(true);

    try {
      const {
        data: { data },
      } = await productsService.getProducts(id);
      setAllOtherProducts(data);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const getCollections = async (id) => {
    try {
      const {
        data: { data },
      } = await productsService.getCollections(id);

      setCollections(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllAgentsPromise = async () => {
    try {
      const { data } = await brandService.getAllBrands();
      const brands = data.data;

      const allBrands = brands[0];

      saveAgents(allBrands);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className="all_prods_container">
        {isLoading && (
          <div className="prod_items_container x_scroll_enabled">
            {[1, 2, 3, 5, 6].map((item, index) => (
              <Loading key={index} />
            ))}
          </div>
        )}

        {searchTerm && (
          <div>
            {searchResult.length > 0 ? (
              <>
                <h2 className="prod_headers">Search: {searchTerm}</h2>
                <div className="prod_items_container">
                  {searchResult.map((item, index) => (
                    <Product key={index} productImage={item.imageUrl}>
                      <Link to={`/${slug}/product/${item.idString}`}>
                        <p className="prod_name">{item.name}</p>
                        <p className="prod_desc">{item.description}</p>
                        <p className="prod_price">
                          {item.currency}{" "}
                          {Math.min(...item.variants.map((x) => x.price))}
                        </p>
                      </Link>
                    </Product>
                  ))}
                </div>
              </>
            ) : (
              <>
                <h2 className="prod_headers">Search: {searchTerm}</h2>
                <div className="empty_state_container">
                  We couldn't find anything for "{searchTerm}". <br />
                  Try something else.
                </div>
              </>
            )}
          </div>
        )}
        {!isLoading && (
          <>
            {/* <div>
              {allOtherProducts.length > 0 && (
                <>
                  <h2 className='prod_headers'>All other products</h2>
                  <div className='prod_items_container'>
                    {allOtherProducts.map((item, index) => (
                      <Link key={index} to={`/product/${item.idString}`}>
                        <Product productImage={item.imageUrl}>
                          <p className='prod_name'>{item.name}</p>
                          <p className='prod_desc'>{item.description}</p>
                          <p className='prod_price'>
                            {item.currency}{' '}
                            {Math.min(...item.variants.map((x) => x.price))}
                          </p>
                        </Product>
                      </Link>
                    ))}
                  </div>
                </>
              )}
            </div> */}

            <div>
              {collections.length > 0 ? (
                <>
                  {/* <h2 className='prod_headers'>All other products</h2> */}
                  <div
                    // id='x_scroll_enabled_prod'
                    className="collections_container"
                  >
                    {collections.map((item, index) => (
                      <React.Fragment key={index}>
                        {item?.productList?.length > 0 && (
                          <div key={index} className="collections_container">
                            <div>
                              <h2 className="prod_headers">{item.name}</h2>

                              <ul>
                                {item.productList.map((prod, i) => (
                                  <Link
                                    to={`/${slug}/product/${prod.idString}`}
                                    key={i}
                                  >
                                    <Product productImage={prod?.imageUrl}>
                                      <p className="prod_name">{prod.name}</p>
                                      <p className="prod_desc">
                                        {prod.description}
                                      </p>
                                      <p className="prod_price">
                                        {prod.currency}{" "}
                                        {Math.min(
                                          ...prod.variants.map((x) => x.price)
                                        )?.toFixed(2)}
                                      </p>
                                    </Product>
                                  </Link>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </>
              ) : (
                <div className="tw-w-full tw-min-h-[50vh] tw-flex tw-items-center tw-justify-center">
                  <p className="tw-text-center">No product found</p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default AllProducts;
