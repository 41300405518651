import React from 'react';
import { Modal } from 'react-bootstrap';
import Countdown from 'react-countdown';
import Button from '../Button';

const PaymentModal = ({
  showPaymentModal,
  handleHide,
  paidForOrder,
  handleHideAndNotify,
  setOpenModal
}) => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      if (!paidForOrder) {
        handleHideAndNotify();
        // notify()
      } else {
        handleHide();
        setOpenModal(true);
      }
      // setPaymentStatus('');
      // handleRequestToken();

      return <></>;
    } else {
      return (
        <span>
          Payment approval expires in: <b>{minutes}</b>mins <b>{seconds}</b>
          sec
        </span>
      );
    }
  };

  return (
    <Modal
      centered
      backdrop='static'
      keyboard={false}
      show={showPaymentModal}
      onHide={handleHide}
      id='CANCEL_MODAL'
    >
      <div className='payment-instruction-container'>
        <div className='d-flex align-items-center justify-content-between'>
          <h5>Payment Instruction</h5>
        </div>

        <Countdown date={Date.now() + 180000} renderer={renderer} />

        <ol className='payment-instruction'>
          <li>
            Kindly approve the payment request pop up that has been sent to your
            phone.
          </li>
          <p>OR</p>
          <li>
            Dial <b>*170#</b> and Choose My Wallet (Option 6).
          </li>
          <li>Choose My Approvals(Option 3).</li>
          <li>Enter your MOMO pin to retrieve your pending approval list.</li>
          <li>Choose My Approvals(Option 3).</li>
          <li>Choose the transaction to approve.</li>
          <li>Click on the Confirm button, once you're done.</li>
        </ol>

        <div className='buttons'>
          <Button
            variant={'primary_button w_full'}
            onClick={() => {
              handleHide();
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentModal;
