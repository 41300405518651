import { useContext, useState, useEffect, useMemo } from "react";
import CartBadge from "../CartBadge";
import ARROWLEFT from "../../assets/icons/arowLeft.svg";
import { Link, useNavigate } from "react-router-dom";
import { useCartStateContext } from "../../context/cartContext";
import companyContext from "../../context/companyContext";
import { Dropdown } from "react-bootstrap";
import {
  getAgents,
  getShowMultiBrands,
  saveItemsInCart,
} from "../../services/localService";

const Navbar = ({ useBackButton }) => {
  const navigate = useNavigate();
  const [company] = useContext(companyContext);
  const { totalItemsInCart, setTotalItemsInCart } = useCartStateContext();
  const agents = getAgents() ?? [];
  const showMultiBrands = getShowMultiBrands();

  const allAgents = useMemo(() => {
    return agents.filter(
      (x) => x.idString !== process.env.REACT_APP_TEST_STORE_1
    );
  }, [agents]);

  const getInitials = (name) => {
    // let name = company?.name;
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

    let initials = "";

    if (name) {
      initials = [...name?.matchAll(rgx)];
    } else {
      initials = [];
    }

    initials = (
      (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
    ).toUpperCase();

    return initials ?? "DM";
  };

  // useEffect(() => {
  //   if (company.name) {

  //   }
  // }, [company]);

  return (
    <nav className="navbar_container">
      {useBackButton ? (
        <div className="goBackContainer">
          <img src={ARROWLEFT} alt="" onClick={() => navigate(-1)} />
        </div>
      ) : (
        <Dropdown>
          <Dropdown.Toggle
            bsPrefix="tw-"
            childBsPrefix="tw-"
            className="!tw-border-none rounded"
            disabled={!showMultiBrands?.state}
          >
            <div className="store_name_and_initials">
              <span className="initials">{getInitials(company?.name)}</span>
              <p className="store_name">{company?.name}</p>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu id={"dropdown-menu"} className="!tw-p-0 !tw-w-auto">
            {allAgents.map((agent) => (
              <Dropdown.Item
                key={agent?.idString}
                className="!tw-py-[0.5rem] tw-border tw-border-[#e3e6f0]"
                onClick={() => {
                  if (agent?.idString !== company?.appId) {
                    saveItemsInCart([]);
                  }
                }}
                active={agent?.idString === company?.appId}
                href={`/agent/${agent?.slug}`}
              >
                <div
                  // reloadDocument
                  className="tw-flex tw-items-center tw-gap-3 tw-w-full"
                >
                  <span className="tw-flex-shrink-0 tw-text-sm tw-bg-[#6E20CA] tw-rounded-[15%] tw-h-[2rem] tw-w-[2rem] tw-flex tw-items-center tw-justify-center tw-text-white text-center">
                    {getInitials(agent?.name)}
                  </span>
                  <p className="tw-text-base">{agent?.name}</p>
                </div>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}

      <CartBadge badgeContent={totalItemsInCart} />
    </nav>
  );
};

export default Navbar;
