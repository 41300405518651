import PIZZA from '../../assets/images/pizza.png';

const Product = ({ productImage, children, ...props }) => {
  return (
    <div className='prod_container' {...props}>
      {/* <div className='loading_'></div> */}
      <div className='md:tw-min-h-[170px] tw-min-h-[100px] tw-max-h-[370px] tw-overflow-hidden'>
        <img
          src={productImage}
          alt=''
          className='tw-object-cover !tw-max-h-[370px] tw-w-full'
        />
      </div>

      <div className='items_container tw-w-full'>{children}</div>
    </div>
  );
};

export default Product;
