import './App.scss';
import { useState } from 'react';
import companyContext from './context/companyContext';
import { CartStateContext } from './context/cartContext';
import { SearchStateContext } from './context/seachedContext';
import { BrowserRouter as Router } from 'react-router-dom';
import Home from './view/home';

function App() {
  const companyHook = useState({});

  return (
    <companyContext.Provider value={companyHook}>
      <SearchStateContext>
        <CartStateContext>
          <Router>
            <Home />
          </Router>
        </CartStateContext>
      </SearchStateContext>
    </companyContext.Provider>
  );
}

export default App;
