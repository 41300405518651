import { useState, useContext, createContext } from 'react';
const Context = createContext();

export const SearchStateContext = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [isFindingBrand, setIsFindingBrand] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSearchInput = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Context.Provider
      value={{
        isError,
        setIsError,
        isFindingBrand,
        setIsFindingBrand,
        searchTerm,
        searchResult,
        setSearchResult,
        handleSearchInput
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useSearchStateContext = () => useContext(Context);
