import { useState, useEffect, useContext } from 'react';
import Navbar from '../Navbar';
import SearchInput from '../Search';
import Select from '../Select';
import Sort from '../Sort';
import { productsService } from '../../services/restService';
import companyContext from '../../context/companyContext';
import { useSearchStateContext } from '../../context/seachedContext';
import { Helmet } from 'react-helmet';
import Navbar2 from '../Navbar/Navbar2';

const Layout2 = ({ children }) => {
  const [company] = useContext(companyContext);
  // const [searchTerm, setSearchTerm] = useState('');
  const { searchTerm, handleSearchInput, setSearchResult } =
    useSearchStateContext();

  // const handleSearch = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  useEffect(() => {
    if (searchTerm) {
      getSearchedProduct();
    }
  }, [searchTerm]);
  const getSearchedProduct = async () => {
    try {
      const {
        data: { data }
      } = await productsService.searchProduct(searchTerm, company.appId);
      setSearchResult(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>DIEM</title>
      </Helmet>

      <main className='!tw-bg-[#F9F9F9]'>
        <Navbar2 />
        <div className='container_width_fluid flex_items'>
          {/* <SearchInput
          onChange={(e) => {
            handleSearchInput(e);
          }}
          value={searchTerm}
          // searchTerm={searchTerm}
        /> */}
        </div>
        <div className='container_width_fluid'>{children}</div>
      </main>
    </>
  );
};

export default Layout2;
