import React from 'react';
import { Toaster } from 'react-hot-toast';

const ToastNotification = () => {
  // const notify = () => toast('Here is your toast.');

  return (
    <div>
      <Toaster position='top-right' reverseOrder={false} />
    </div>
  );
};

export default ToastNotification;
