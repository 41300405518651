import React from 'react';

const SkeletonBrandCard = () => {
  return (
    <li className='tw-bg-white tw-rounded-lg tw-px-2 tw-py-4 tw-overflow-hidden lg:tw-col-span-3 md:tw-col-span-4 md:tw-w-full tw-w-[270px] tw-flex-shrink-0 tw-flex tw-items-center tw-flex-row tw-gap-3 tw-animate-pulse'>
      <div className='tw-w-[100px] tw-h-[68px] tw-overflow-hidden tw-rounded-md tw-bg-gray-200 tw-shadow-[0px_4px_4px_rgba(0,0,0,0.25)] tw-flex-shrink-0' />
      <div className='tw-flex tw-flex-shrink-0 tw-w-full tw-flex-col tw-gap-1 tw-overflow-hidden'>
        <div className='tw-h-5 tw-bg-gray-200 tw-rounded-md tw-w-[120px] tw-animate-pulse' />
        <div className='tw-h-3 tw-bg-gray-200 tw-rounded-md tw-w-[80px] tw-animate-pulse' />
      </div>
    </li>
  );
};

export default SkeletonBrandCard;
