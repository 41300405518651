import React, { useState, useMemo, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import {
  useJsApiLoader,
  GoogleMap,
  MarkerF,
  StandaloneSearchBox
} from '@react-google-maps/api';
import { BiTargetLock } from 'react-icons/bi';
import SEARCH_ICON from '../../assets/icons/searchIcon.svg';
import { useEffect } from 'react';
import Geocode from 'react-geocode';
import Button from '../Button';

const googleMapApiKey = process.env.REACT_APP_MAP_API_KEY;

const lib = ['places'];

Geocode.setApiKey(googleMapApiKey);
Geocode.enableDebug();
const MapComponent = ({
  showMap,
  handleHideMap,
  setUserAddress,
  userAddress
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: googleMapApiKey,
    libraries: lib
  });
  const [mapref, setMapRef] = useState(null);
  const [center, setCenter] = useState(null);

  const refmap = useRef();

  const [userLocation, setUserLocation] = useState({
    lat: 5.6037379,
    lng: -0.1869579
  });
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));

  useEffect(() => {
    if (userAddress && Object.keys(userAddress).length > 0) {
      const location = userAddress.geometry.location;
      const lat = location.lat();
      const lng = location.lng();

      setUserLocation({ ...userAddress, lat, lng });
    }
  }, [userAddress]);

  const getMyLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      // new window.google.maps
      // console.log(latitude, longitude);
      setUserLocation({ ...userLocation, lat: latitude, lng: longitude });
      handleFindGeoAddress(latitude, longitude);
    });
  };

  useMemo(() => {
    // console.log(center);
    if (center) {
      // setUserLocation(center);
    }
  }, [center]);
  // const destiantionRef = useRef();
  // const originRef = useRef();

  // async function calculateRoute() {
  //   if (originRef.current.value === '' || destiantionRef.current.value === '') {
  //     return;
  //   }
  //   // eslint-disable-next-line no-undef
  //   const directionsService = new google.maps.DirectionsService();
  //   const results = await directionsService.route({
  //     origin: originRef.current.value,
  //     destination: destiantionRef.current.value,
  //     // eslint-disable-next-line no-undef
  //     travelMode: google.maps.TravelMode.DRIVING
  //   });
  //   setDirectionsResponse(results);
  //   setDistance(results.routes[0].legs[0].distance.text);
  //   setDuration(results.routes[0].legs[0].duration.text);
  // }

  const latLng = (latlng) => {
    return latlng;
  };

  // eslint-disable-next-line no-undef
  // const res = google.maps.places.;

  const handleBoundsChanged = () => {
    let center = {};
    // const mapCenter = refmap.current.state.map.getCenter();
    //get map center

    // center = { lat: mapCenter.lat(), lng: mapCenter.lng() };
    // console.log(mapCenter);
    if (mapref) {
      // console.log(mapref);
      // const newCenter = mapref.getCenter();
      // const lat = newCenter.lat();
      // const lng = newCenter.lng();
      // center = {
      //   lat,
      //   lng
      // };
    }
    // console.log({ center });
    setCenter(center);
    // setUserLocation(center);
  };

  const handleOnLoad = (map) => {
    //  console.log('on load');
    setMapRef(map);
  };

  const onUnmount = (e) => {
    setMapRef(null);
    // console.log(e, 'unmounted');
  };

  const centers = [
    {
      lat: 37.772,
      lng: -122.214
    },
    {
      lat: 5.5967744,
      lng: -0.2260992
    },
    {
      lat: 37.832,
      lng: -122.424
    }
  ];

  const [searchBox, setSearchBox] = useState(null);

  const onPlacesChanged = () => {
    const address = searchBox.getPlaces();

    if (address && address.length > 0) {
      let location = address[0].geometry.location;
      const lat = location.lat();
      const lng = location.lng();

      const suburb = address[0].address_components.find((st) => {
        return st?.types?.includes('sublocality');
      });
      const city = address[0].address_components.find((st) => {
        return st?.types?.includes('locality');
      });
      const country = address[0].address_components.find((st) => {
        return st?.types?.includes('country');
      });
      const street = address[0].address_components.find((st) => {
        return st?.types?.includes('route');
      });

      setUserAddress({
        suburb: suburb ? suburb?.long_name : '',
        city: city ? city?.long_name : '',
        longitude: lng,
        latitude: lat,
        formattedAddress: address[0].formatted_address,
        formatted_address: address[0].formatted_address,
        streetOrFarm: street ? street?.long_name : '',
        country: country ? country?.long_name : '',
        geometry: address[0].geometry
      });
    }
  };

  const onSBLoad = (ref) => {
    setSearchBox(ref);
  };

  const handleFindGeoAddress = async (lat, lng) => {
    try {
      const { results } = await Geocode.fromLatLng(lat, lng);

      let route = [];

      if (results && results.length > 1) {
        let location = results.filter((loc) => {
          return loc.types.includes('route');
        });

        if (location.length > 0) {
          route.push(...location);
        } else {
          let location = results.filter((loc) => {
            return loc.types.includes('street_address');
          });
          route.push(...location);
        }
      }

      const suburb = route[0].address_components.find((st) => {
        return st?.types?.includes('sublocality');
      });
      const city = route[0].address_components.find((st) => {
        return st?.types?.includes('locality');
      });
      const country = route[0].address_components.find((st) => {
        return st?.types?.includes('country');
      });
      const street = route[0].address_components.find((st) => {
        return st?.types?.includes('route');
      });

      setUserAddress({
        suburb: suburb ? suburb?.long_name : '',
        city: city ? city?.long_name : '',
        longitude: lng,
        latitude: lat,
        formattedAddress: route[0].formatted_address,
        formatted_address: route[0].formatted_address,
        streetOrFarm: street ? street?.long_name : '',
        country: country ? country?.long_name : '',
        geometry: {
          ...route[0].geometry,
          location: {
            ...route[0].geometry.location,
            lat: () => latLng(lat),
            lng: () => latLng(lng)
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  if (!isLoaded) {
    return <div>Loading....</div>;
  }

  return (
    <Modal size='lg' show={showMap} onHide={handleHideMap} centered>
      <div className='map_container'>
        <GoogleMap
          center={userLocation}
          zoom={17}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            zoomControl: true,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
            styles: [
              {
                elementType: 'labels',
                featureType: 'poi.business',
                stylers: [{ visibility: 'off' }]
              }
            ]
          }}
          onLoad={(map) => {
            setMap(map);
            // console.log(map);

            handleOnLoad(map);
          }}
          onClick={(e) => {
            let lat = e.latLng.lat();
            let lng = e.latLng.lng();

            setUserLocation({ ...userLocation, lat, lng });
            handleFindGeoAddress(lat, lng);
          }}
          onBoundsChanged={(e) => {
            // setUserLocation(center);
          }}
          onUnmount={onUnmount}
          ref={refmap}
          onCenterChanged={() => {
            console.log('center changed');
            handleBoundsChanged();
          }}
        >
          <MarkerF
            position={userLocation}
            animation={window.google.maps.Animation.DROP}
            onClick={(e) => {
              console.log(e, 'clicked');
            }}
            // label='hello world'
          />

          {/* {directionsResponse && (
          <DirectionsRenderer directions={directionsResponse} />
        )} */}
        </GoogleMap>

        <div className='location_search_container'>
          <StandaloneSearchBox
            onLoad={onSBLoad}
            onPlacesChanged={onPlacesChanged}
          >
            <>
              <img src={SEARCH_ICON} alt='' />
              <input
                type='text'
                placeholder='Search'
                defaultValue={userAddress?.formatted_address}
                // ref={destiantionRef}
              />
            </>
          </StandaloneSearchBox>
        </div>

        <div className='me_container'>
          <Button
            variant={'secondary_button'}
            type='button'
            data-toggle='tooltip'
            data-placement='top'
            title='Your Location'
            onClick={getMyLocation}
          >
            <BiTargetLock style={{ fontSize: '25px' }} />
          </Button>
        </div>
        <div className='continue_container'>
          <Button variant={'primary_button'} onClick={handleHideMap}>
            <span>Continue</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MapComponent;
