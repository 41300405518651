import React from 'react';

const MobileCount = ({ total, handleIncrease, handleDecrease }) => {
  return (
    <div className='mobile_cart_count_container'>
      <button onClick={handleDecrease}>-</button>
      <p className='total'>{total}</p>
      <button onClick={handleIncrease}>+</button>
    </div>
  );
};

export default MobileCount;
