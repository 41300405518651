import React from 'react';
import CLOSED_ICON from '../../assets/icons/closed.svg';

const Closed = ({ company }) => {
  const workingHours = company?.weekdayHours;

  const openingTime = new Date().setHours(
    workingHours?.startTime?.hour,
    workingHours?.startTime?.minute,
    workingHours?.startTime?.second,
    workingHours?.startTime?.nano
  );

  const closingTime = new Date().setHours(
    workingHours?.closingTime?.hour,
    workingHours?.closingTime?.minute,
    workingHours?.closingTime?.second,
    workingHours?.closingTime?.nano
  );
  function tConvert(time) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? 'am' : 'pm';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join('');
  }

  const closeHours = new Date(closingTime).toTimeString().slice(0, 5);
  const openHours = new Date(openingTime).toTimeString().slice(0, 5);

  return (
    <div className='closed_container'>
      <img src={CLOSED_ICON} alt='' />

      <div className='closed_desc_container'>
        <h3>Sorry we are currently closed.</h3>
        <p>
          Unfortunately we are closed at this time. Kindly place your order
          between the hours of {tConvert(openHours)} and {tConvert(closeHours)}.
        </p>
      </div>
    </div>
  );
};

export default Closed;
