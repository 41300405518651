import React from 'react';
import { Modal } from 'react-bootstrap';
import SUCCESS from '../../../assets/icons/success.svg';
import CLOSE from '../../../assets/icons/close.svg';

const SuccessModal = ({ openModal, handleCloseModal }) => {
  return (
    <Modal
      centered
      id={'success_modal'}
      show={openModal}
      onHide={handleCloseModal}
      backdrop='static'
    >
      <div className='succes_modal_container'>
        <div className='close_btn'>
          <img src={CLOSE} alt='' onClick={handleCloseModal} />
        </div>

        <div className='success_m_container'>
          <img src={SUCCESS} alt='' />

          <div>
            <h1>Thank you for choosing us</h1>
            <p>
              Your order is being processed. We will reach out to you once your
              order is ready.
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SuccessModal;
