import produce from "immer";
import Pusher from "pusher-js";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import TRASH from "../../assets/icons/trash.svg";
import {
  Button,
  Count,
  MobileCount,
  Navbar,
  PaymentModal,
  useWIndowSize,
} from "../../components";
import { useCartStateContext } from "../../context/cartContext";
import companyContext from "../../context/companyContext";
import {
  getCompanyId,
  getSlug,
  removeAllItemsFromCart,
  saveItemsInCart,
} from "../../services/localService";
import { fetchAndUpdateBrandDetails } from "../../utils/helpers";
import OrderSummary from "./orderSummary";
import SuccessModal from "./success";

const Cart = () => {
  const companyID = getCompanyId();
  const dimensions = useWIndowSize();
  const [company] = useContext(companyContext);
  const navigate = useNavigate();
  const { itemsInCart, setItemsInCart, setTotalItemsInCart } =
    useCartStateContext();

  const companySlug = getSlug();

  const [paymentType, setPaymentType] = useState("CASH");
  const [deliveryOption, setDeliveryOption] = useState("Delivery");
  const [openModal, setOpenModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paidForOrder, setPaidForOrder] = useState(false);

  const handleHide = () => {
    setShowPaymentModal(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setItemsInCart([]);
    setTotalItemsInCart(0);
    removeAllItemsFromCart();

    navigate(companySlug ? `/${companySlug}` : "/");
  };

  const handleIncrease = (item) => {
    setItemsInCart(
      produce((draft) => {
        let updated = draft.find(
          (x) =>
            x.idString === item.idString &&
            x.variants.name === item.variants.name
        );
        updated.variants.qty = Number(updated.variants.qty) + 1;
      })
    );
  };
  const handleDecrease = (item, index) => {
    setItemsInCart(
      produce((draft) => {
        let updated = draft.find(
          (x) =>
            x.idString === item.idString &&
            x.variants.name === item.variants.name
        );
        if (item.variants.qty >= 2) {
          updated.variants.qty = Number(updated.variants.qty) - 1;
        } else {
          draft.splice(index, 1);
        }
      })
    );
  };

  useEffect(() => {
    let totalCartItems = itemsInCart?.reduce((acc, item) => {
      return acc + Number(item?.variants?.qty);
    }, 0);

    setTotalItemsInCart(totalCartItems);

    saveItemsInCart(itemsInCart ?? []);
  }, [itemsInCart]);

  useEffect(() => {
    const pusher = new Pusher("36324cda8a34fe672b3c", {
      cluster: "mt1",
    });

    const channel = pusher.subscribe(`momoWebhook`);

    channel.bind(`momoPayment`, function (data) {
      const { transactionStatus } = data;

      if (transactionStatus?.toLowerCase() === "pending") {
        setShowPaymentModal(true);
      } else if (transactionStatus?.toLowerCase() === "success") {
        setPaidForOrder(true);
        setShowPaymentModal(false);
        setOpenModal(true);
      } else {
        setShowPaymentModal(false);
        notify();
      }
    });

    return () => {
      pusher.unsubscribe("momoWebhook");
    };
  }, []);

  const notify = () =>
    toast.error("Your order couldn’t be placed. Kindly try again.", {
      duration: 5000,
    });

  const handleHideAndNotify = () => {
    notify();
    setShowPaymentModal(false);
  };

  useEffect(() => {
    if (itemsInCart?.[0]?.appId) {
      fetchAndUpdateBrandDetails(itemsInCart?.[0]?.appId).then((c) => {
        if (c && c?.enableDelivery) {
          setDeliveryOption("Delivery");
        } else {
          setDeliveryOption("Pick up");
        }
      });
    }
  }, [itemsInCart?.[0]?.appId]);

  // useEffect(() => {
  //   if (!companyID) {
  //     fetchAndUpdateBrandDetails(itemsInCart[0]?.appId);
  //   }
  // }, [companyID]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DIEM - {`${company?.name}`}</title>
      </Helmet>
      <Navbar useBackButton={true} />

      <div className="cart_container">
        {itemsInCart.length < 1 ? (
          <>
            <div className="cart_items_container">
              <h1>Cart</h1>

              <div className="empty_cart_container">
                <p>Your cart is empty.</p>
                <Link to="/">
                  <Button variant="primary_button">Home</Button>
                </Link>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="cart_items_container">
              <h1>Cart</h1>

              <ul className="single_item_container">
                {itemsInCart?.map((item, index) => (
                  <div key={index}>
                    <li className="single_item">
                      <div className="prod_img_container tw-h-[110px] tw-overflow-hidden">
                        <img
                          src={item?.imageUrl}
                          alt=""
                          className="tw-object-cover tw-h-full tw-w-full"
                        />
                      </div>
                      <div className="single_prod_container">
                        <p className="prod_name capitalize">
                          {item.name}{" "}
                          <span className="cart_item_variant">
                            ({item?.variants?.name})
                          </span>
                        </p>
                        <p className="prod_desc">{item.description}</p>
                        <p className="prod_price">
                          Ghs{" "}
                          {(
                            Number(item?.variants?.price) *
                            Number(item?.variants?.qty)
                          ).toFixed(2)}
                        </p>
                      </div>

                      {dimensions.width > 820 && (
                        <div>
                          <Count
                            value={item.variants.qty}
                            handleIncrease={() => {
                              handleIncrease(item);
                            }}
                            handleDecrease={() => {
                              handleDecrease(item, index);
                            }}
                          />
                        </div>
                      )}
                    </li>

                    {dimensions.width <= 820 && (
                      <div className="d-flex align-items-center justify-content-between tw-mt-1">
                        <MobileCount
                          total={item?.variants?.qty}
                          handleIncrease={() => handleIncrease(item)}
                          handleDecrease={() => {
                            handleDecrease(item, index);
                          }}
                        />
                        <img
                          src={TRASH}
                          alt=""
                          onClick={() => {
                            setItemsInCart(
                              produce((draft) => {
                                draft.splice(index, 1);
                              })
                            );
                          }}
                        />
                      </div>
                    )}

                    <hr />
                  </div>
                ))}
              </ul>
            </div>
            <div className="order_summary_container">
              <h1>Order summary</h1>

              <OrderSummary
                paymentType={paymentType}
                setPaymentType={setPaymentType}
                deliveryOption={deliveryOption}
                setDeliveryOption={setDeliveryOption}
                cartItems={itemsInCart}
                setTotalItemsInCart={setTotalItemsInCart}
                setOpenModal={setOpenModal}
                setShowPaymentModal={setShowPaymentModal}
              />
            </div>
          </>
        )}
      </div>

      <SuccessModal openModal={openModal} handleCloseModal={handleCloseModal} />
      <PaymentModal
        showPaymentModal={showPaymentModal}
        handleHide={handleHide}
        paidForOrder={paidForOrder}
        handleHideAndNotify={handleHideAndNotify}
        setOpenModal={setOpenModal}
      />
    </>
  );
};

export default Cart;
