const isBrandClosed = ({ brand }) => {
  if (brand && brand.weekdayHours) {
    const weekdayHours = brand?.weekdayHours;
    const now = new Date().getTime();
    const openingTime = new Date().setHours(
      weekdayHours?.startTime?.hour,
      weekdayHours?.startTime?.minute,
      weekdayHours?.startTime?.second,
      weekdayHours?.startTime?.nano
    );

    const closingTime = new Date().setHours(
      weekdayHours?.closingTime?.hour,
      weekdayHours?.closingTime?.minute,
      weekdayHours?.closingTime?.second,
      weekdayHours?.closingTime?.nano
    );

    if (openingTime <= now && now < closingTime) {
      return false;
    }

    return true;
  }
};

export default isBrandClosed;
