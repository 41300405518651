import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Navbar, Product, Button } from "../../components";
import { Link } from "react-router-dom";
import { productsService, brandService } from "../../services/restService";
import { useCartStateContext } from "../../context/cartContext";
import produce from "immer";
import {
  saveItemsInCart,
  getCompanyId,
  saveCompanyId,
  getItemsInCart,
  removeAllItemsFromCart,
  saveCompany,
  getCompany,
  saveSlug,
  getSlug,
} from "../../services/localService";
import { Helmet } from "react-helmet";
import companyContext from "../../context/companyContext";
import { ScaleLoader } from "react-spinners";
import { useSearchStateContext } from "../../context/seachedContext";
const SingleProduct = () => {
  const { productId, slug } = useParams();
  const [items, setItems] = useState([]);

  const { setIsError, setIsFindingBrand } = useSearchStateContext();
  const [isLoading, setIsLoading] = useState(true);
  const appId = getCompanyId();
  const brand = getCompany();
  const localItemsInCart = getItemsInCart();
  const [company, setCompany] = useContext(companyContext);

  const {
    selectedProduct,
    setSelectedProduct,
    itemsInCart,
    setItemsInCart,
    // noItemInCart,
    // setNoItemInCart,
    addToCart,
    removeFromCart,
    totalItemsInCart,
    setTotalItemsInCart,
  } = useCartStateContext();

  const [selectedVariant, setSelectedVariant] = useState({
    name: "",
    quantity: 0,
  });

  const [tempProd, setTempProd] = useState({});
  const [allSelectedProd, setAllSelectedProd] = useState([]);
  const [allSelected, setAllSelected] = useState([]);
  const [noItemInCart, setNoItemInCart] = useState(false);
  const [tempItemsToCart, setTempItemsToCart] = useState([]);

  const getBrand = async (slug) => {
    // setIsFindingBrand(true);

    saveSlug(slug);
    let slug_ = slug?.replace(/ /g, "_");

    try {
      const {
        data: { data },
      } = await brandService.getBrandBySlug(slug_);
      let company = data[0];
      console.log("🚀 ~ getBrand ~ company:", company);
      const weekdayHours = data[0].productOrderingProperties.weekdayHours;

      saveCompany({
        appId: company?.idString,
        name: company.name,
        weekdayHours,
      });
      setCompany({
        appId: company?.idString,
        name: company.name,
        weekdayHours,
      });
      saveCompanyId(company?.idString);

      removeAllItemsFromCart();
      setItemsInCart([]);

      // setIsFindingBrand(false);
      return;
    } catch (error) {
      console.log(error);

      getBrandByID(slug);
      return;
    }
  };

  const getBrandByID = async (slug) => {
    // setIsFindingBrand(true);

    try {
      const {
        data: { data },
      } = await brandService.getBrand(slug);
      let company = data[0];
      console.log("🚀 ~ getBrandByID ~ company:", company);

      const weekdayHours = data[0]?.productOrderingProperties?.weekdayHours;

      saveCompany({
        appId: company?.idString,
        name: company.name,
        weekdayHours,
      });
      setCompany({
        appId: company?.idString,
        name: company.name,
        weekdayHours,
      });
      saveCompanyId(company?.idString);

      removeAllItemsFromCart();
      setItemsInCart([]);

      // setIsFindingBrand(false);
      return;
    } catch (error) {
      console.log(error);

      // setIsFindingBrand(false);
      setIsError(true);
      return;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (
      (appId && brand && slug?.replace(/_/g, " ") === brand.name) ||
      appId === slug
    ) {
      setCompany(brand);
      saveSlug(brand?.name?.replace(/ /g, "_"));
      if (localItemsInCart) {
        setItemsInCart(localItemsInCart);
      }
      setIsLoading(false);
    } else {
      getBrand(slug);
    }
  }, []);

  useEffect(() => {
    getSingleProduct(productId);
  }, []);

  const getSingleProduct = async (prodId) => {
    setIsLoading(true);
    try {
      let {
        data: { data },
      } = await productsService.getSingleProduct(prodId);

      data[0].variants.forEach((el) => {
        el.qty = 0;
        el.writable = true;
      });

      setSelectedProduct(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedProduct.length > 0) {
      try {
        const prod = { ...selectedProduct[0] };
        const tempSelectedProd = [...selectedProduct];

        let selectedProdVariants = [];
        itemsInCart?.filter((item) => {
          if (item?.idString === selectedProduct[0]?.idString) {
            selectedProdVariants.push(item.variants);
            return item.variants;
          }
        });

        let tempSelectedProdVariants = [...selectedProdVariants];

        let hash = Object.create(null);
        prod?.variants?.forEach((orVarr) => (hash[orVarr?.name] = orVarr));
        tempSelectedProd[0]?.variants?.forEach(
          (orVarr) => (hash[orVarr?.name] = orVarr)
        );
        tempSelectedProdVariants?.forEach((orVarr) =>
          Object.assign(hash[orVarr?.name], orVarr)
        );

        setItems(tempSelectedProd);
        setTempProd(prod);
      } catch (error) {
        console.log(error);
      }
    }
  }, [totalItemsInCart, selectedProduct]);

  useEffect(() => {
    saveItemsInCart(itemsInCart ?? []);

    let numberOfItemsInCart = itemsInCart?.reduce((acc, item) => {
      return acc + Number(item?.variants?.qty);
    }, 0);

    setTotalItemsInCart(isNaN(numberOfItemsInCart) ? 0 : numberOfItemsInCart);
  }, [itemsInCart]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DIEM - {`${company?.name}`}</title>
      </Helmet>

      <Navbar useBackButton={true} />

      {isLoading ? (
        <div className="_loading">
          <ScaleLoader
            color={"#6E20CA"}
            loading={isLoading}
            // cssOverride={}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <div className="single_product_container tw-w-full">
          <Product productImage={items.length > 0 && items[0]?.imageUrl}>
            {items.length > 0 &&
              items?.map((item, i) => (
                <div className="tw-w-full" key={i}>
                  <p className="prod_name">{item?.name}</p>
                  <div className="tw-w-full">
                    <label htmlFor="" className="label">
                      Product description
                    </label>
                    <p className="prod_desc">{item?.description}</p>
                  </div>

                  <div className="d-flex flex-column gap_10">
                    <div className="size_and_quantity_container d-flex align-items-center gap_8  justify-content-between">
                      <div className="flex-fill">
                        <ul className="variants_container tw-w-full ">
                          {item?.variants?.map((variant, index) => (
                            <li className="tw-w-full" key={index}>
                              {variant.qty > 0 && (
                                <div
                                  className="dec_ tw-flex-shrink-0"
                                  onClick={() => {
                                    setItems(
                                      produce((draft) => {
                                        const v = draft[0].variants.find(
                                          (x) => x.name === variant.name
                                        );

                                        if (v.qty >= 1) {
                                          v.qty = Number(v.qty) - 1;
                                        }
                                      })
                                    );
                                    setSelectedVariant({
                                      ...selectedVariant,
                                      name: variant.name,
                                    });
                                    removeFromCart(item, variant?.name, index);
                                  }}
                                >
                                  <p>&#8722;</p>
                                </div>
                              )}

                              <div className="d-flex align-items-center variant_ gap_3 tw-w-full">
                                <p className="capitalize tw-w-full">
                                  {variant?.name}
                                </p>
                                <p className="tw-w-full">
                                  Ghs {variant?.price}
                                </p>
                                <p className="tw-w-full">{variant?.qty}</p>
                              </div>

                              <div
                                className="inc_ tw-flex-shrink-0"
                                onClick={() => {
                                  setItems(
                                    produce((draft) => {
                                      const v = draft[0].variants.find(
                                        (x) => x.name === variant.name
                                      );

                                      v.qty = Number(v.qty) + 1;
                                    })
                                  );

                                  setSelectedVariant({
                                    ...selectedVariant,
                                    name: variant.name,
                                  });
                                  let qty = variant.qty + 1;
                                  addToCart(item, variant?.name, index);
                                }}
                              >
                                <p>&#43;</p>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div className="d-flex w_full  align-items-center gap_8 justify-content-between">
                      {/* <Button
                    onClick={() => {
                      // handleAddToCart(
                      //   prodToAddToCart,
                      //   selectedVariant.quantity
                      // );
                    }}
                    variant={'secondary_button flex-fill w_full'}
                  >
                    Add to cart
                  </Button> */}

                      {allSelected?.length > 0 || itemsInCart?.length > 0 ? (
                        <Link
                          className="w_full"
                          to={"/cart"}
                          onClick={() => {
                            let otherItems = [];
                            let tempSameItems = [];

                            // const tempC = itemsInCart.find((item) => {
                            //   if (item?.idString !== items[0]?.idString) {
                            //     otherItems.push(item);
                            //   } else {
                            //     tempSameItems.push(item);
                            //   }
                            // });

                            // let toCart = allSelected.filter((item) => {
                            //   return item.variants.qty !== 0;
                            // });

                            // setItemsInCart([...otherItems, ...toCart]);
                          }}
                        >
                          <Button variant={"primary_button flex-fill w_full"}>
                            Buy now
                          </Button>
                        </Link>
                      ) : (
                        <div className="_no_item_btn">
                          {noItemInCart && (
                            <p className="err_">
                              Add atleast 1 item to your cart
                            </p>
                          )}

                          <Button
                            variant={"primary_button "}
                            onClick={() => setNoItemInCart(true)}
                          >
                            Buy now
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </Product>
        </div>
      )}
    </>
  );
};

export default SingleProduct;
