// setters
export const saveCompanyId = (id) => {
  try {
    localStorage.setItem('diem_id', id);
  } catch (error) {
    console.log(error);
  }
};
export const saveCompany = (data) => {
  try {
    let body = JSON.stringify(data);
    localStorage.setItem('diem_brand', body);
  } catch (error) {
    console.log(error);
  }
};
export const saveAgents = (data) => {
  try {
    let body = JSON.stringify(data);
    localStorage.setItem('diem_agents', body);
  } catch (error) {
    console.log(error);
  }
};
export const saveSlug = (slug) => {
  try {
    sessionStorage.setItem('diem_slug', slug);
  } catch (error) {
    console.log(error);
  }
};

export const saveShowMultiBrands = (data) => {
  try {
    let body = JSON.stringify(data);

    localStorage.setItem('diem_multi_agents', body);
  } catch (error) {
    console.log(error);
  }
};

export const saveItemsInCart = (data) => {
  try {
    const items = JSON.stringify(data);
    localStorage.setItem('mycart', items);
  } catch (error) {
    console.log(error);
  }
};

// getters
export const getCompanyId = () => {
  try {
    const companyId = localStorage.getItem('diem_id');

    return companyId;
  } catch (error) {
    console.log(error);
  }
};
export const getSlug = () => {
  try {
    const slug = sessionStorage.getItem('diem_slug');

    return slug;
  } catch (error) {
    console.log(error);
  }
};

export const getShowMultiBrands = () => {
  try {
    const data = localStorage.getItem('diem_multi_agents');

    if (data) {
      const itemsToObject = JSON.parse(data);

      return itemsToObject;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getCompany = () => {
  try {
    const data = localStorage.getItem('diem_brand');

    if (data) {
      const itemsToObject = JSON.parse(data);

      return itemsToObject;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAgents = () => {
  try {
    const data = localStorage.getItem('diem_agents');

    if (data) {
      const itemsToObject = JSON.parse(data);

      return itemsToObject;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
  }
};

export const getItemsInCart = () => {
  try {
    const items = localStorage.getItem('mycart');

    if (items) {
      const itemsToObject = JSON.parse(items);

      return itemsToObject;
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
  }
};

export const removeAllItemsFromCart = () => {
  localStorage.removeItem('mycart');
};
