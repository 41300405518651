import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Product,
  ProductSkeleton,
  RecommendedProductSkeletonCard,
  SkeletonBrandCard,
} from "../../components";
import Layout2 from "../../components/Layout/Layout2";
import { useCartStateContext } from "../../context/cartContext";
import { brandService, productsService } from "../../services/restService";
import brandInitials from "../../utils/brandInitials";
import isBrandClosed from "../../utils/isBrandClosed";
import shuffle from "../../utils/shuffle";

const MultiStore = () => {
  const {
    selectedProduct,
    setSelectedProduct,
    itemsInCart,
    noItemInCart,
    setNoItemInCart,
    addToCart,
    removeFromCart,
  } = useCartStateContext();

  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const q = searchParams.get("q");

  const [greeting, setGreeting] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [collections, setCollections] = useState([]);
  const [popularBrands, setPopularBrands] = useState([]);
  const [isFetchingBrands, setIsFetchingBrands] = useState(true);
  const [isFetchingTodaysOffers, setIsFetchingTodaysOffers] = useState(false);
  const [isFetchingRecommendedProducts, setIsFetchingRecommendedProducts] =
    useState(false);

  const [isFetchingFilteredProducts, setIsFetchingFilteredProducts] =
    useState(false);

  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [todaysOffers, setTodaysOffers] = useState([]);

  const [showProduct, setShowProduct] = useState(false);
  const [tempItemsToCart, setTempItemsToCart] = useState([]);
  // const [selectedProduct, setSelectedProduct] = useState({});

  useEffect(() => {
    const hours = new Date().getHours();

    if (hours < 12) {
      setGreeting("Good Morning");
    } else if (hours < 18) {
      setGreeting("Good Afternoon");
    } else {
      setGreeting("Good Evening");
    }
  }, []);

  const handleGetAllCollections = async () => {
    try {
      const {
        data: { data },
      } = await productsService.getAllCollections();
      const content = data;

      const contents_name = content.map((item) => item.name);
      setCollections([...contents_name]);
      if (!q) {
        navigate("?q=" + contents_name[0]);
      } else {
        navigate("?q=" + q);
      }
      // setSelectedCategory(contents_name[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetAllBrands = async () => {
    setIsFetchingBrands(true);
    try {
      const {
        data: { data },
      } = await brandService.getAllBrands();
      const brands = data[0];

      const filteredBrands = brands?.filter(
        (x) => x?.idString !== process.env.REACT_APP_TEST_STORE_1
      );

      const openedBrands = filteredBrands
        .slice()
        .filter((brand) => !isBrandClosed(brand));
      const closedBrands = filteredBrands
        .slice()
        .filter((brand) => isBrandClosed(brand));

      setPopularBrands([...shuffle(openedBrands), ...closedBrands]);
      setIsFetchingBrands(false);
    } catch (error) {
      console.log(error);
      setIsFetchingBrands(false);
    }
  };

  const handleGetRecommendations = async () => {
    setIsFetchingRecommendedProducts(true);
    setIsFetchingTodaysOffers(true);
    try {
      const {
        data: { data },
      } = await productsService.getPopularOffers();
      const recommendedOffers = data[0];
      const shuffledOffers = shuffle(recommendedOffers);

      const rec_offers = shuffledOffers.slice(0, 4) ?? [];
      const todays_offers = shuffledOffers.slice(4) ?? [];

      setRecommendedProducts(rec_offers);
      setTodaysOffers(todays_offers);

      setIsFetchingRecommendedProducts(false);
      setIsFetchingTodaysOffers(false);
    } catch (error) {
      console.log(error);
      setIsFetchingRecommendedProducts(false);
      setIsFetchingTodaysOffers(false);
    }
  };

  useEffect(() => {
    handleGetAllCollections();
    handleGetAllBrands();
    handleGetRecommendations();
  }, []);

  const handleGetFilteredProducts = async (category) => {
    setIsFetchingFilteredProducts(true);
    try {
      const {
        data: { data },
      } = await productsService.getProductsByCollectionName(category);
      const filteredProducts = data[0];
      setFilteredProducts(filteredProducts);
      setIsFetchingFilteredProducts(false);
    } catch (error) {
      console.log(error);
      setIsFetchingFilteredProducts(false);
    }
  };

  useEffect(() => {
    if (!selectedCategory) {
      // handleGetRecommendations();
      setFilteredProducts([]);
    } else {
      handleGetFilteredProducts(selectedCategory);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (q) {
      setSelectedCategory(q);
    }
  }, [q]);

  return (
    <Layout2>
      <div className="tw-flex tw-flex-col tw-gap-8 bg md:tw-pt-0 tw-pt-4">
        <div className="tw-text-xl">
          <p>Hello,</p>
          <p className="tw-font-bold">{greeting}</p>
        </div>
        <ul className="tw-flex tw-items-center tw-gap-2 tw-py-4 tw-w-full tw-z-50 px-4 tw-overflow-x-auto tw-sticky bg-white tw-top-0">
          {/* <li
            className={`tw-text-sm tw-capitalize tw-font-medium tw-rounded-[100px] tw-flex-shrink-0 tw-cursor-pointer tw-py-1 tw-px-4 ${'tw-bg-[#fff]'} `}
          >
            Filter by:
          </li> */}
          {collections.map((collection, i) => (
            <li
              key={i}
              onClick={() => {
                navigate("?q=" + collection);
              }}
              className={`tw-text-sm tw-capitalize tw-font-medium tw-rounded-[100px] tw-flex-shrink-0 tw-cursor-pointer tw-py-1 tw-px-4 ${
                selectedCategory === collection
                  ? "tw-text-white tw-bg-[#6E20CA]"
                  : "tw-bg-[#fff]"
              } `}
            >
              {collection?.toLowerCase()}
            </li>
          ))}
        </ul>
        <div className="tw-flex tw-flex-col tw-gap-6">
          {selectedCategory && (
            <div className="tw-text-xl tw-flex tw-flex-col tw-gap-3">
              <p className="tw-font-bold ">
                <span className="tw-font-normal">Filter by:</span>{" "}
                <span className="tw-capitalize">{selectedCategory}</span>
              </p>

              <ul className="md:tw-grid tw-grid-cols-12 tw-flex tw-overflow-x-auto md:tw-overflow-x-hidden  tw-items-center tw-gap-4 md:tw-gap-y-4">
                {isFetchingFilteredProducts ? (
                  <>
                    {[...Array(4)].map((_, i) => (
                      <RecommendedProductSkeletonCard key={i} />
                    ))}
                  </>
                ) : (
                  <>
                    {filteredProducts.map((product, i) => (
                      <li
                        key={i}
                        className="lg:tw-col-span-3 md:tw-col-span-4 tw-w-[200px] md:tw-w-full tw-bg-white tw-rounded-lg tw-p-3 tw-flex-shrink-0 tw-flex tw-flex-col tw-gap-1"
                      >
                        <Link
                          to={`/${product.appId}/product/${product.idString}`}
                          className="tw-overflow-hidden tw-w-full"
                        >
                          <img
                            src={product?.imageUrl}
                            alt=""
                            className="tw-w-[200px] tw-h-[150px] md:tw-h-[200px] md:tw-w-full tw-object-cover"
                          />
                        </Link>
                        <div className="tw-flex tw-flex-shrink-0 tw-w-full tw-flex-col tw-gap-1">
                          <Link
                            to={`/${product.appId}/product/${product.idString}`}
                            className="tw-font-medium tw-text-base !tw-truncate tw-w-full"
                          >
                            {product?.name}
                          </Link>
                          <Link
                            to={`/${product.appId}/product/${product.idString}`}
                            className="tw-font-normal !tw-truncate tw-text-sm tw-w-full"
                          >
                            {product?.description}
                          </Link>
                          <div className="tw-flex tw-items-center tw-justify-between tw-mt-1.5">
                            <Link
                              to={`/${product.appId}/product/${product.idString}`}
                              className="tw-font-bold tw-text-sm md:text-base"
                            >
                              {product.currency}{" "}
                              {Math.min(
                                ...(product?.variants?.map((x) => x?.price) ?? [
                                  0,
                                ])
                              ).toFixed(2)}
                            </Link>

                            <button
                              onClick={() => {
                                const data = { ...product };
                                data?.variants?.forEach((el) => {
                                  el.qty = 0;
                                  el.writable = true;
                                });

                                const indexOfMinProductPrice =
                                  product?.variants.findIndex(
                                    (x) =>
                                      x.price ===
                                      Math.min(
                                        ...(product?.variants?.map(
                                          (x) => x?.price
                                        ) ?? [0])
                                      )
                                  );

                                // setSelectedProduct(data);

                                addToCart(
                                  data,
                                  data.variants[indexOfMinProductPrice]?.name,
                                  indexOfMinProductPrice
                                );

                                // setShowProduct(true);
                              }}
                              className="tw-bg-[#6E20CA] tw-transition-all tw-duration-150 tw-ease-in hover:tw-scale-105 tw-text-white tw-flex tw-items-center tw-justify-center tw-h-5 tw-w-5 tw-rounded"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}
                  </>
                )}
              </ul>
            </div>
          )}

          <div className="tw-text-xl tw-flex tw-flex-col tw-gap-3">
            <p className="tw-font-bold">Our Recommendations</p>

            <ul className="md:tw-grid tw-grid-cols-12 tw-flex tw-overflow-x-auto md:tw-overflow-x-hidden  tw-items-center tw-gap-4 md:tw-gap-y-4">
              {isFetchingRecommendedProducts ? (
                <>
                  {[...Array(4)].map((_, i) => (
                    <RecommendedProductSkeletonCard key={i} />
                  ))}
                </>
              ) : (
                <>
                  {recommendedProducts.map((product, i) => (
                    <li
                      key={i}
                      className="lg:tw-col-span-3 md:tw-col-span-4 tw-w-[200px] md:tw-w-full tw-bg-white tw-rounded-lg tw-p-3 tw-flex-shrink-0 tw-flex tw-flex-col tw-gap-1"
                    >
                      <Link
                        to={`/${product.appId}/product/${product.idString}`}
                        className="tw-overflow-hidden tw-w-full"
                      >
                        <img
                          src={product?.imageUrl}
                          alt=""
                          className="tw-w-[200px] tw-h-[150px] md:tw-h-[200px] md:tw-w-full tw-object-cover"
                        />
                      </Link>
                      <div className="tw-flex tw-flex-shrink-0 tw-w-full tw-flex-col tw-gap-1">
                        <Link
                          to={`/${product.appId}/product/${product.idString}`}
                          className="tw-font-medium tw-text-base !tw-truncate tw-w-full"
                        >
                          {product?.name}
                        </Link>
                        <Link
                          to={`/${product.appId}/product/${product.idString}`}
                          className="tw-font-normal !tw-truncate tw-text-sm tw-w-full"
                        >
                          {product?.description}
                        </Link>
                        <div className="tw-flex tw-items-center tw-justify-between tw-mt-1.5">
                          <Link
                            to={`/${product.appId}/product/${product.idString}`}
                            className="tw-font-bold tw-text-sm md:text-base"
                          >
                            {product.currency}{" "}
                            {Math.min(
                              ...(product?.variants?.map((x) => x?.price) ?? [
                                0,
                              ])
                            ).toFixed(2)}
                          </Link>

                          <button
                            onClick={() => {
                              const data = { ...product };
                              data?.variants?.forEach((el) => {
                                el.qty = 0;
                                el.writable = true;
                              });

                              const indexOfMinProductPrice =
                                product?.variants.findIndex(
                                  (x) =>
                                    x.price ===
                                    Math.min(
                                      ...(product?.variants?.map(
                                        (x) => x?.price
                                      ) ?? [0])
                                    )
                                );

                              // setSelectedProduct(data);

                              addToCart(
                                data,
                                data.variants[indexOfMinProductPrice]?.name,
                                indexOfMinProductPrice
                              );

                              // setShowProduct(true);
                            }}
                            className="tw-bg-[#6E20CA] tw-transition-all tw-duration-150 tw-ease-in hover:tw-scale-105 tw-text-white tw-flex tw-items-center tw-justify-center tw-h-5 tw-w-5 tw-rounded"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </>
              )}
            </ul>
          </div>

          <div className="tw-text-xl tw:min-h-[125px] tw-flex tw-flex-col tw-gap-2 ">
            <p className="tw-font-bold">Popular Brands</p>

            <div className="tw-flex tw-flex-col tw-gap-1">
              {isFetchingBrands ? (
                <ul className="md:tw-grid tw-grid-cols-12 tw-flex tw-overflow-x-auto md:tw-overflow-x-hidden  tw-items-center tw-gap-4 md:tw-gap-y-8">
                  {[...Array(4)].map((_, i) => (
                    <SkeletonBrandCard key={i} />
                  ))}
                </ul>
              ) : (
                <>
                  <ul className="md:tw-grid tw-grid-cols-12 tw-overflow-y-hidden tw-flex tw-overflow-x-auto md:tw-overflow-x-hidden   tw-items-center tw-gap-4 md:tw-gap-y-8">
                    {popularBrands.slice(0, 4).map((brand, i) => (
                      <li
                        key={i}
                        className="tw-bg-white hover:tw-scale-105  tw-duration-200 tw-ease-in tw-rounded-lg  tw-overflow--hidden  lg:tw-col-span-3 md:tw-col-span-4 md:tw-w-full tw-w-[270px] tw-flex-shrink-0"
                      >
                        <Link
                          to={`/agent/${brand?.slug?.replace(/ /g, "_")}`}
                          className="tw-px-2 tw-py-4 tw-overflow-x-hidden tw-w-full  tw-flex tw-items-center tw-flex-row tw-gap-3"
                        >
                          {brand?.avatarUrl ? (
                            <div className="tw-w-[100px] tw-h-[68px] tw-overflow-hidden  tw-rounded-md tw-bg-white tw-shadow-[0px_4px_4px_rgba(0,0,0,0.25)] tw-flex-shrink-0">
                              <img
                                src={brand?.avatarUrl}
                                alt=""
                                className="tw-w-full tw-h-full tw-rounded-md tw-bg-white tw-flex-shrink-0 tw-object-cover"
                              />
                            </div>
                          ) : (
                            <div className="tw-w-[100px]  tw-font-bold tw-text-white tw-flex tw-items-center tw-justify-center tw-bg-[#6E20CA] tw-h-[68px] tw-overflow-hidden  tw-rounded-md tw-shadow-[0px_4px_4px_rgba(0,0,0,0.25)] tw-flex-shrink-0">
                              {brandInitials(brand?.name)}
                            </div>
                          )}

                          <div className="tw-flex tw-w-full tw-flex-col tw-gap-1 tw-overflow-hidden">
                            <p className="tw-font-bold tw-text-base tw-truncate">
                              {brand.name}
                            </p>
                            {/* <p className='tw-font-normal !tw-truncate tw-text-sm tw-w-full'>
                        Burgers Milkshakes
                      </p> */}
                            {isBrandClosed(brand) ? (
                              <p className="tw-text-[#ff0000] tw-text-xs">
                                Closed
                              </p>
                            ) : (
                              <p className="tw-text-[#6E20CA] tw-text-xs">
                                Opened
                              </p>
                            )}
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <ul className="md:tw-grid tw-grid-cols-12 tw-flex tw-overflow-x-auto md:tw-overflow-x-hidden md:tw-overflow-y-hidden  tw-items-center tw-gap-4 md:tw-gap-y-8">
                    {popularBrands.slice(4).map((brand, i) => (
                      <li
                        key={i}
                        className="tw-bg-white hover:tw-scale-105  tw-duration-200 tw-ease-in tw-rounded-lg  tw-overflow--hidden  lg:tw-col-span-3 md:tw-col-span-4 md:tw-w-full tw-w-[270px] tw-flex-shrink-0"
                      >
                        <Link
                          to={`/agent/${brand?.slug?.replace(/ /g, "_")}`}
                          className="tw-px-2 tw-py-4 tw-overflow-x-hidden tw-w-full  tw-flex tw-items-center tw-flex-row tw-gap-3"
                        >
                          {brand?.avatarUrl ? (
                            <div className="tw-w-[100px] tw-h-[68px] tw-overflow-hidden  tw-rounded-md tw-bg-white tw-shadow-[0px_4px_4px_rgba(0,0,0,0.25)] tw-flex-shrink-0">
                              <img
                                src={brand?.avatarUrl}
                                alt=""
                                className="tw-w-full tw-h-full tw-rounded-md tw-bg-white tw-flex-shrink-0 tw-object-cover"
                              />
                            </div>
                          ) : (
                            <div className="tw-w-[100px] tw-font-bold tw-text-white tw-flex tw-items-center tw-justify-center tw-bg-[#6E20CA] tw-h-[68px] tw-overflow-hidden  tw-rounded-md tw-shadow-[0px_4px_4px_rgba(0,0,0,0.25)] tw-flex-shrink-0">
                              {brandInitials(brand?.name)}
                            </div>
                          )}

                          <div className="tw-flex tw-w-full tw-flex-col tw-gap-1 tw-overflow-hidden">
                            <p
                              title={brand?.name}
                              className="tw-font-bold tw-text-base tw-w-full tw-truncate"
                            >
                              {brand?.name}
                            </p>
                            {/* <p className='tw-font-normal !tw-truncate tw-text-sm tw-w-full'>
                        Burgers Milkshakes
                      </p> */}
                            {isBrandClosed(brand) ? (
                              <p className="tw-text-[#ff0000] tw-text-xs">
                                Closed
                              </p>
                            ) : (
                              <p className="tw-text-[#6E20CA] tw-text-xs">
                                Opened
                              </p>
                            )}
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>

          {todaysOffers?.length > 0 && (
            <div className="tw-text-xl tw-flex tw-flex-col tw-gap-3">
              <p className="tw-font-bold">Today’s Offers</p>

              <ul className="md:tw-grid tw-w-full tw-overflow-hidden tw-grid-cols-12 tw-flex tw-flex-col tw-gap-4 md:tw-gap-y-8">
                {isFetchingTodaysOffers ? (
                  <>
                    {[...Array(4)].map((_, i) => (
                      <ProductSkeleton key={i} />
                    ))}
                  </>
                ) : (
                  <>
                    {" "}
                    {todaysOffers?.map((product, i) => (
                      <Link
                        to={`/${product.appId}/product/${product.idString}`}
                        key={i}
                        className="lg:tw-col-span-3 tw-overflow-hidden md:tw-col-span-4 tw-w-full tw-flex-shrink-0 tw-flex md:tw-items-start tw-items-center tw-flex-row md:tw-flex-col md:tw-gap-1 tw-gap-2"
                      >
                        <img
                          src={product?.imageUrl}
                          alt=""
                          className="tw-w-[150px] tw-h-[150px] tw-flex-shrink-0 md:tw-w-full tw-object-cover"
                        />
                        <div className="tw-flex tw-flex-shrink-0 tw-w-full tw-flex-col tw-gap-1">
                          <p className="tw-font-medium !tw-truncate tw-text-base">
                            {product?.name}
                          </p>
                          <p className="tw-font-normal !tw-truncate tw-text-sm tw-w-full">
                            {product?.description}
                          </p>
                          <p className="tw-font-bold tw-text-sm md:text-base">
                            FROM {product?.currency}{" "}
                            {Math.min(
                              ...(product?.variants?.map((x) => x?.price) ?? [
                                0,
                              ])
                            ).toFixed(2)}
                          </p>
                        </div>
                      </Link>
                    ))}
                  </>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>

      <Modal
        centered
        show={showProduct}
        onHide={() => {
          setSelectedProduct({});
          setShowProduct(false);
        }}
      >
        <div className="tw-w-full tw-relative">
          <button
            onClick={() => {
              setSelectedProduct({});
              setShowProduct(false);
            }}
            className="tw-absolute tw-z-10 tw-top-4 tw-right-4 tw-bg-white tw-text-red-500 tw-rounded-full"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="tw-w-6 tw-h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <Product productImage={selectedProduct?.imageUrl}>
            <div className="tw-w-full tw-px-2 tw-pb-6 tw-flex tw-flex-col tw-gap-2">
              <p className="prod_name">{selectedProduct?.name}</p>
              <div className="tw-w-full tw-text-sm">
                <label htmlFor="" className="label">
                  Product description
                </label>
                <p className="prod_desc">{selectedProduct?.description}</p>
              </div>

              <div className="d-flex flex-column gap_10">
                <div className="size_and_quantity_container d-flex align-items-center gap_8  justify-content-between">
                  <div className="flex-fill">
                    <ul className="tw-flex tw-flex-col tw-gap-4 tw-w-full ">
                      {selectedProduct?.variants?.map((variant, index) => (
                        <li
                          className="tw-w-full tw-flex tw-items-center tw-gap-2 tw-justify-between"
                          key={index}
                        >
                          {variant.qty > 0 && (
                            <div
                              className="tw-rounded-full tw-h-[35px] tw-w-[35px] tw-bg-[#ff00001a] tw-flex tw-items-center tw-justify-center tw-text-[#ff0000] tw-flex-shrink-0"
                              onClick={() => {
                                removeFromCart(
                                  selectedProduct,
                                  variant?.name,
                                  index
                                );
                              }}
                            >
                              <p>&#8722;</p>
                            </div>
                          )}

                          <div className="tw-flex md:tw-flex-row tw-flex-col  tw-w-full">
                            <p className="capitalize tw-w-full tw-border tw-rounded tw-px-1 tw-text-center tw-py-2 tw-text-sm">
                              {variant?.name}
                            </p>
                            <p className="tw-w-full tw-border tw-rounded tw-px-1 tw-text-center tw-py-2 tw-text-sm">
                              {selectedProduct?.currency}{" "}
                              {variant?.price?.toFixed(2)}
                            </p>
                            <p className="tw-w-full tw-border tw-rounded tw-px-1 tw-text-center tw-py-2 tw-text-sm">
                              {variant?.qty}
                            </p>
                          </div>

                          <div
                            className="tw-rounded-full tw-h-[35px] tw-w-[35px] tw-bg-[#6E20CA1a] tw-flex tw-items-center tw-justify-center tw-text-[#6E20CA] tw-flex-shrink-0"
                            // onClick={() => {
                            //   addToCart(selectedProduct, variant?.name, index);
                            // }}
                          >
                            <p>&#43;</p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="d-flex w_full  align-items-center gap_8 justify-content-between">
                  {/* <Button
                    onClick={() => {
                      // handleAddToCart(
                      //   prodToAddToCart,
                      //   selectedVariant.quantity
                      // );
                    }}
                    variant={'secondary_button flex-fill w_full'}
                  >
                    Add to cart
                  </Button> */}

                  {itemsInCart?.length > 0 ? (
                    <Link className="w_full" to={"/cart"} onClick={() => {}}>
                      <Button variant={"primary_button flex-fill w_full"}>
                        Buy now
                      </Button>
                    </Link>
                  ) : (
                    <div className="_no_item_btn">
                      {noItemInCart && (
                        <p className="err_">Add atleast 1 item to your cart</p>
                      )}

                      <Button
                        variant={"primary_button "}
                        onClick={() => setNoItemInCart(true)}
                      >
                        Buy now
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Product>
        </div>
      </Modal>
    </Layout2>
  );
};

export default MultiStore;
