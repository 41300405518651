const Loading = () => {
  return (
    <div className='items_container '>
      <div className='loading_'></div>

      <p style={{ height: '15px', width: '100px' }} className='loading_'></p>
      <p style={{ height: '15px', width: '250px' }} className='loading_'></p>
      <p style={{ height: '15px', width: '70px' }} className='loading_'></p>
    </div>
  );
};

export default Loading;
