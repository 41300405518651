import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneInput2 = ({ labelName, labelFor, ...props }) => {
  return (
    <div className='primary_input_container'>
      {labelName && <label htmlFor={labelFor}>{labelName}</label>}

      <PhoneInput {...props} />
    </div>
  );
};

export default PhoneInput2;
