// import { useState, useContext, useEffect, createContext } from 'react';
// import { saveItemsInCart, getItemsInCart } from '../../services/localService';
// const Context = createContext();

// export const CartStateContext = ({ children }) => {
//   const itemsInCart = getItemsInCart();
//   const [showCart, setShowCart] = useState(false);
//   const [cartItems, setCartItems] = useState([]);
//   const [totalPrice, setTotalPrice] = useState(0);
//   const [totalItemsInCart, setTotalItemsInCart] = useState(0);
//   const [singleItemQty, setSingleItemQty] = useState(1);
//   const [selectedProduct, setSelectedProduct] = useState({});

//   const increaseQty = () => {
//     setSingleItemQty((prev) => prev + 1);
//   };

//   const decreaseQty = () => {
//     setSingleItemQty((prevQty) => {
//       if (prevQty - 1 < 1) return;

//       return prevQty - 1;
//     });
//   };

//   useEffect(() => {
//     if (itemsInCart) {
//       setCartItems(itemsInCart);

//       let totalCartItems = itemsInCart?.reduce((acc, item) => {
//         return acc + Number(item?.variants?.qty);
//       }, 0);

//       setTotalItemsInCart(totalCartItems);
//     }
//   }, []);

//   const handleAddToCart = (product, variantIndex, quantity) => {
//     const checkProductsInCart = cartItems.find(
//       (item) =>
//         item.idString === product.idString &&
//         item.variants[0].name === product.variants[variantIndex].name
//     );

//     // setTotalPrice(
//     //   (prevAmount) =>
//     //     prevAmount + Number(product.variants[0].price) * Number(quantity)
//     // );

//     if (checkProductsInCart) {
//       let updatedCartItems = cartItems.map((item) => {
//         if (
//           item.idString === product.idString &&
//           item.variants[0].name === product.variants[variantIndex].name
//         ) {
//           return {
//             ...item,
//             variants: [
//               {
//                 ...item.variants[0],
//                 quantity: Number(quantity)
//               }
//             ]
//           };
//         } else {
//           return item;
//         }
//       });

//       setCartItems(updatedCartItems);
//     } else {
//       product.variants[0].quantity = Number(quantity);

//       setCartItems([
//         ...cartItems,
//         { ...product, variants: [product.variants[variantIndex]] }
//       ]);
//     }

//     // add toast notification
//   };

//   const updatedCartItemsQty = (index, myCase, product) => {
//     let cartItemsArrToBeUpdated = cartItems;

//     switch (myCase) {
//       case 'inc':
//         cartItemsArrToBeUpdated[index].quantity = product.quantity + 1;
//         setTotalPrice((prevAmount) => prevAmount + product.price);
//         setTotalItemsInCart((prevQty) => prevQty + 1);
//         break;

//       case 'dec':
//         if (product.quantity <= 1) return;

//         cartItemsArrToBeUpdated[index].quantity = product.quantity - 1;
//         setTotalPrice((prevAmount) => prevAmount - product.price);
//         setTotalItemsInCart((prevQty) => prevQty - 1);
//         break;

//       default:
//         return;
//     }

//     cartItemsArrToBeUpdated[index].price = product.quantity * product.price;

//     setCartItems([...cartItemsArrToBeUpdated]);
//   };

//   const removeItemFromCart = (index, product) => {
//     let cartItemsToBeUpdated = cartItems;

//     setTotalItemsInCart((prevQty) => prevQty - product.quantity);
//     setTotalPrice(
//       (prevAmount) => prevAmount - product.price * product.quantity
//     );

//     cartItemsToBeUpdated.splice(index, 1);
//     setCartItems([...cartItemsToBeUpdated]);
//   };

//   return (
//     <Context.Provider
//       value={{
//         cartItems,
//         totalItemsInCart,
//         totalPrice,
//         increaseQty,
//         decreaseQty,
//         handleAddToCart,
//         removeItemFromCart,
//         updatedCartItemsQty,
//         singleItemQty,
//         setCartItems,
//         setTotalItemsInCart,
//         setSelectedProduct,
//         selectedProduct
//       }}
//     >
//       {children}
//     </Context.Provider>
//   );
// };

// export const useCartStateContext = () => useContext(Context);

import { useState, useContext, useEffect, createContext } from 'react';
import { saveItemsInCart, getItemsInCart } from '../../services/localService';
const Context = createContext();

export const CartStateContext = ({ children }) => {
  const localItemsInCart = getItemsInCart();
  const [selectedProduct, setSelectedProduct] = useState({});
  const [itemsInCart, setItemsInCart] = useState([]);
  const [noItemInCart, setNoItemInCart] = useState(false);
  const [totalItemsInCart, setTotalItemsInCart] = useState(0);

  function addToCart(product, variantName, variantIndex) {
    let cart = [...itemsInCart];
    // Check if the product variant already exists in the cart

    if (cart.length > 0 && cart[0].appId !== product.appId) {
      cart = [];
    }

    const cartItemIndex = cart.findIndex(
      (item) =>
        item.idString === product.idString &&
        item.variants?.name === variantName
    );

    if (cartItemIndex === -1) {
      // If the product variant does not exist in the cart, add it as a new item
      cart.push({
        ...product,
        variants: { ...product.variants[variantIndex], qty: 1 }
      });

      // If this is the first product being added to the cart, update the selectedProduct state

      setSelectedProduct({
        ...product,
        variants: [{ ...product.variants[variantIndex], qty: 1 }]
      });
    } else {
      // If the product variant already exists in the cart, update its quantity
      cart[cartItemIndex].variants.qty += 1;

      setSelectedProduct({
        ...product,
        variants: [
          {
            ...product.variants[variantIndex],
            qty: product.variants[variantIndex].qty + 1
          }
        ]
      });
    }

    let totalCartItems = cart?.reduce((acc, item) => {
      return acc + Number(item?.variants?.qty);
    }, 0);

    setTotalItemsInCart(totalCartItems);

    // saveItemsInCart(cart);
    setItemsInCart(cart);
  }

  function removeFromCart(product, variantName, variantIndex) {
    let cart = [...itemsInCart];
    // Check if the product variant already exists in the cart
    const cartItemIndex = cart?.findIndex(
      (item) =>
        item?.idString === product?.idString &&
        item?.variants?.name === variantName
    );

    if (cartItemIndex !== -1) {
      // If the product variant already exists in the cart, update its quantity
      cart[cartItemIndex].variants.qty -= 1;

      setSelectedProduct({
        ...product,
        variants: [
          {
            ...product.variants[variantIndex],
            qty: product.variants[variantIndex].qty - 1
          }
        ]
      });

      if (cart[cartItemIndex].variants.qty === 0) {
        cart.splice(cartItemIndex, 1);

        setSelectedProduct({
          ...product,
          variants: [
            {
              ...product.variants[variantIndex],
              qty: 0
            }
          ]
        });
      }
    }

    let totalCartItems = cart?.reduce((acc, item) => {
      return acc + Number(item?.variants?.qty);
    }, 0);

    setTotalItemsInCart(totalCartItems);

    // saveItemsInCart(cart);
    setItemsInCart(cart);
  }

  useEffect(() => {
    if (localItemsInCart) {
      setItemsInCart(localItemsInCart);

      let totalCartItems = localItemsInCart?.reduce((acc, item) => {
        return acc + Number(item?.variants?.qty);
      }, 0);

      setTotalItemsInCart(totalCartItems);
    }
  }, []);

  return (
    <Context.Provider
      value={{
        selectedProduct,
        setSelectedProduct,
        itemsInCart,
        setItemsInCart,
        noItemInCart,
        setNoItemInCart,
        addToCart,
        removeFromCart,
        totalItemsInCart,
        setTotalItemsInCart
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useCartStateContext = () => useContext(Context);
