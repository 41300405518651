import { saveCompany, saveCompanyId, saveSlug } from "../services/localService";
import { brandService } from "../services/restService";

export const fetchAndUpdateBrandDetails = async (companyId) => {
  try {
    const {
      data: { data },
    } = await brandService.getBrand(companyId);
    let company = data[0];

    const weekdayHours = data[0]?.productOrderingProperties?.weekdayHours;
    const enablePayments = data[0]?.productOrderingProperties?.enablePayment;
    const enableDelivery = data[0]?.productOrderingProperties?.delivery;

    const d = {
      appId: company?.idString,
      name: company?.name,
      slug: company?.slug,
      enablePayments: enablePayments,
      enableDelivery: Boolean(enableDelivery?.status),
      weekdayHours,
    };

    saveCompany(d);

    saveSlug(company?.slug ?? company?.name?.replace(/ /g, "_"));

    saveCompanyId(company?.idString);

    return d;
  } catch (error) {
    console.log(error);

    return;
  }
};
