import React from 'react';

const ProductSkeleton = () => {
  return (
    <li className='lg:tw-col-span-3 tw-overflow-hidden md:tw-col-span-4 tw-w-full tw-flex-shrink-0 tw-flex md:tw-items-start tw-items-center tw-flex-row md:tw-flex-col md:tw-gap-1 tw-gap-2 animate-pulse'>
      <div className='tw-w-[150px] tw-bg-gray-200 tw-h-[150px] md:tw-w-full tw-rounded tw-flex-shrink-0 tw-mr-3'></div>
      <div className='tw-flex tw-flex-shrink-0 tw-w-full tw-flex-col tw-gap-1'>
        <div className='tw-w-3/4 tw-h-4 tw-bg-gray-200 tw-rounded tw-animate-pulse'></div>
        <div className='tw-w-full tw-h-3 tw-bg-gray-200 tw-rounded tw-animate-pulse'></div>
        <div className='tw-w-1/2 tw-h-2 tw-bg-gray-200 tw-rounded tw-animate-pulse'></div>
        <div className='tw-w-1/4 tw-bg-gray-200 tw-h-2 tw-rounded tw-animate-pulse'></div>
      </div>
    </li>
  );
};

export default ProductSkeleton;
