import React from 'react';

const RecommendedProductSkeletonCard = () => {
  return (
    <li className='lg:tw-col-span-3 md:tw-col-span-4 tw-w-[200px] tw-overflow-hidden md:tw-w-full tw-bg-white tw-rounded-lg tw-p-3 tw-flex-shrink-0 tw-flex tw-flex-col tw-gap-1 animate-pulse'>
      <div className='tw-overflow-hidden tw-w-full'>
        <div className='tw-w-[200px] tw-bg-gray-200 tw-h-[150px] md:tw-h-[200px] md:tw-w-full tw-rounded tw-animate-pulse'></div>
      </div>
      <div className='tw-flex tw-flex-shrink-0 tw-w-full tw-flex-col tw-gap-1'>
        <div className='tw-w-3/4 tw-h-4 tw-bg-gray-200 tw-rounded tw-animate-pulse'></div>
        <div className='tw-w-1/2 tw-h-3 tw-bg-gray-200 tw-rounded tw-animate-pulse'></div>
        <div className='tw-w-1/4 tw-h-2 tw-bg-gray-200 tw-rounded tw-animate-pulse'></div>
        <div className='tw-flex tw-items-center tw-justify-between'>
          <div className='tw-w-1/4 tw-bg-gray-200 tw-h-3 tw-rounded tw-animate-pulse'></div>
          <div className='tw-w-1/4 tw-bg-gray-200 tw-h-5 tw-rounded tw-animate-pulse'></div>
        </div>
      </div>
    </li>
  );
};

export default RecommendedProductSkeletonCard;
