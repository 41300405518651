import axios from 'axios';

const restAgent = axios.create({
  baseURL: process.env.REACT_APP_R2MP,
  headers: {}
});

const headerAgent = axios.create({
  baseURL: 'http://header.rancardmobility.com',
  headers: {}
});

const getConfig = () => {
  return {
    headers: {},
    params: {}
  };
};

export const brandService = {
  getAllBrands: () => {
    const config = getConfig();
    return restAgent.get(`/api/v1/diem-agent/allagents`, config);
  },
  getBrand: (companyId) => {
    const config = getConfig();
    return restAgent.get(`/api/v1/collection/getBrand/${companyId}`, config);
  },
  getBrandBySlug: (slug) => {
    const config = getConfig();
    return restAgent.get(`/api/v1/agent-plan/slug/${slug}`, config);
  }
};
export const productsService = {
  getCollections: (companyId) => {
    const config = getConfig();
    return restAgent.get(
      `/api/v1/product/${companyId}/productCollections`,
      config
    );
  },
  getAllCollections: () => {
    const config = getConfig();
    return restAgent.get(`/api/v1/collection/diem/all`, config);
  },
  // getAllCollections: () => {
  //   const config = getConfig();
  //   return restAgent.get(`/api/v1/collection/allLiveCollections`, config);
  // },
  getProductsByCollectionName: (collectionName) => {
    const config = getConfig();
    return restAgent.get(
      `/api/v1/product/getProductsByCollectionName?collectionName=${collectionName}`,
      config
    );
  },
  getPopularOffers: () => {
    const config = getConfig();
    return restAgent.get(`/api/v1/product/popular/all`, config);
  },

  getProducts: (companyId) => {
    const config = getConfig();
    return restAgent.get(`/api/v1/product/${companyId}`, config);
  },
  getSingleProduct: (productId) => {
    const config = getConfig();
    return restAgent.get(`/api/v1/product/${productId}/single`, config);
  },
  searchProduct: (name, companyId) => {
    const config = getConfig();
    return restAgent.get(
      `/api/v1/product/search/${companyId}?word=${name}`,
      config
    );
  }
};

export const orderSummaryService = {
  getBranches: (companyId) => {
    const config = getConfig();
    return restAgent.get(`/api/v1/branch/${companyId}`, config);
  },
  order: (companyId, cart) => {
    const config = getConfig();
    return restAgent.post(`/api/v1/order/webstore/${companyId}`, cart, config);
  },
  payForOrder: (companyId, orderId, amount, msisdn) => {
    const config = getConfig();
    return restAgent.post(
      `/api/v1/order/payForOrder/${orderId}/${amount}/${msisdn}/${companyId}`,

      config
    );
  },
  getSingleProduct: (productId) => {
    const config = getConfig();
    return restAgent.get(`/api/v1/product/${productId}/single`, config);
  }
};

export const getHeaders = () => {
  const config = getConfig();
  return headerAgent.get('/decrypt', config);
};
